import { Routes } from '@angular/router';
import { AdminGuard } from '../../admin/admin-guard.service';

export const Full_ROUTES: Routes = [
  {
    path: 'page',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('../../modules/projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('../../admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  }
];
