import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {StatoSessioneService} from '../../../../shared/services/stato-sessione.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-coverage',
  templateUrl: './coverage.component.html',
  styleUrls: ['./coverage.component.scss']
})
export class CoverageComponent implements OnInit {
  story: string;
  generatedTests: any[] = []; // To store the generated test cases
  models: string[];
  selectedModel: string;
  analisi: string;
  // roba da spinner
  startTime: number;
  intervalId: any;
  elapsedTime = 0; // Time in milliseconds
  timing = false;

  constructor(private apiService: ApiService, private sessioneService: StatoSessioneService, private modalService: NgbModal,
              private toastr: ToastrService, private spinner: NgxSpinnerService, ) { }

  ngOnInit(): void {
    this.models = ['Haiku', 'Sonnet', 'Sonnet 3.5', 'Omni', 'Gemini Flash', 'Gemini Pro'];

    this.sessioneService.generatedTests$.subscribe( generatedTests => {
      this.generatedTests = generatedTests;
    });

    this.sessioneService.selectedModel$.subscribe(model => {
      this.selectedModel = model;
    });

    this.sessioneService.refinedStoryString$.subscribe( story => {
      this.story = story;
    })
  }

  openCoverageModal(modalContent: any) {
    this.modalService.dismissAll();
    this.modalService.open(modalContent, { size: 'xl', scrollable: true });
  }

  updateSelectedModel() {
    this.sessioneService.updateSelectedModel(this.selectedModel);
  }

  getAnalysis() {
    if (!this.story) {
      this.toastr.error('Non hai caricato una storia!', 'Error');
      return
    }

    if (!this.generatedTests) {
      this.toastr.error('Non ci sono test!', 'Error');
      return
    }

    // Log the entire generatedTests array
    console.log('Generated Tests:', JSON.stringify(this.generatedTests, null, 2));

    // For now, let's convert each test object to a string representation
    const testStrings = this.generatedTests.map(test => JSON.stringify(test));

    const tests = testStrings.join('\n').trim();
    console.log('Tests to send:', tests);

    // inizio feedback d'attesa utente
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.timing = true;
    this.startTime = Date.now(); // Capture start time
    this.intervalId = setInterval(() => {
      this.updateElapsedTime();
    }, 1000); // Update every 1000 milliseconds (1 second)
    // fine feedback d'attesa utente

    this.apiService.analyzeCoverage(this.story, tests, this.selectedModel)
      .subscribe(response => {
        this.analisi = response;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.error('Error in getAnalysis:', error);
      });
  }

  updateElapsedTime() {
    const now = Date.now();
    this.elapsedTime = Math.floor((now - this.startTime) / 1000); // Convert to seconds
  }

}
