import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StatoSessioneService } from '../../../../shared/services/stato-sessione.service';
// @ts-ignore
import Quill from 'quill';

@Component({
  selector: 'app-story-viewer',
  templateUrl: './story-viewer.component.html',
  styleUrls: ['./story-viewer.component.scss']
})
export class StoryViewerComponent implements OnInit {
  @ViewChild('editor', { static: true }) editorElement: ElementRef;

  refinedStoryString: string;
  fileName: string;
  isCollapsed = false;
  quill: Quill;

  constructor(private sessioneService: StatoSessioneService) { }

  ngOnInit(): void {
    this.sessioneService.refinedStoryString$.subscribe(refinedStoryString => {
      this.refinedStoryString = refinedStoryString;
      if (this.quill && this.quill.root.innerHTML !== this.refinedStoryString) {
        this.quill.root.innerHTML = this.refinedStoryString;
      }
    });

    this.sessioneService.fileName$.subscribe(filename => {
      this.fileName = filename;
    });

    this.initQuill();
  }

  initQuill() {
    this.quill = new Quill(this.editorElement.nativeElement, {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean']
        ]
      }
    });

    this.quill.root.innerHTML = this.refinedStoryString;

    this.quill.on('text-change', () => {
      this.onStoryChange();
    });
  }

  onStoryChange() {
    const newStory = this.quill.root.innerHTML;
    this.refinedStoryString = newStory;
    this.sessioneService.updateRefinedStoryString(newStory);
  }

  downloadHtmlFile() {
    const blob = new Blob([this.refinedStoryString], { type: 'text/html' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.fileName}.html`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getEditorContent(): string {
    return this.refinedStoryString;
  }
}
