import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Note} from '../../modules/projects/story/notes/notes.component';


@Injectable({
  providedIn: 'root'
})
export class StatoSessioneService {
  private triggerGenerateTests = new Subject<boolean>();

  private bodySubject = new BehaviorSubject<string>('' );
  bodySubject$ = this.bodySubject.asObservable();

  private selectedModel = new BehaviorSubject<string>('' );
  selectedModel$ = this.selectedModel.asObservable();

  private templates = new BehaviorSubject<any[]>([] );
  templates$ = this.templates.asObservable();

  private selectedTemplate = new BehaviorSubject<string>('' );
  selectedTemplate$ = this.selectedTemplate.asObservable();

  private selectedTestStyle = new BehaviorSubject<string>('' );
  selectedTestStyle$ = this.selectedTestStyle.asObservable();

  private istruzioniScriviTest = new BehaviorSubject<string>('' );
  istruzioniScriviTest$ = this.istruzioniScriviTest.asObservable();

  private notesAdded = false;

  private additionalContextSubject = new BehaviorSubject<string>('');
  additionalContext$ = this.additionalContextSubject.asObservable();

  private fileNameSubject = new BehaviorSubject<string>('');
  fileName$ = this.fileNameSubject.asObservable();

  private testFileNameSubject = new BehaviorSubject<string>('');
  testFileName$ = this.testFileNameSubject.asObservable();

  private refinedStorySubject = new BehaviorSubject<string[]>([]);
  refinedStory$ = this.refinedStorySubject.asObservable();

  private refinedStoryStringSubject = new BehaviorSubject<string>('');
  refinedStoryString$ = this.refinedStoryStringSubject.asObservable();

  private generatedTestsSubject = new BehaviorSubject<string[]>([]);
  generatedTests$ = this.generatedTestsSubject.asObservable();

  private columnsSubject = new BehaviorSubject<string[]>([]);
  columns$ = this.columnsSubject.asObservable();

  private retrievedNotes = new BehaviorSubject<Note[]>([]);
  retrievedNotes$ = this.retrievedNotes.asObservable();

  private projectId = new BehaviorSubject<string>('' );
  projectId$ = this.projectId.asObservable();

  private projectLanguage = new BehaviorSubject<string>('' );
  projectLanguage$ = this.projectLanguage.asObservable();

  testCount$: Observable<number> = this.generatedTests$.pipe(
    map(tests => tests.length)
  );

  resetAll() {
    this.bodySubject.next('');
    this.additionalContextSubject.next('');
    this.fileNameSubject.next('');
    this.testFileNameSubject.next('');
    this.refinedStorySubject.next([]);
    this.refinedStoryStringSubject.next('');
    this.generatedTestsSubject.next([]);
    this.istruzioniScriviTest.next('');
    this.templates.next([]);
    this.selectedTemplate.next('');
    this.retrievedNotes.next([]);
    this.projectId.next('');
    this.templates.next([]);
    this.projectLanguage.next('')
  }

  // Observable for components to subscribe to
  get triggerGenerateTests$() {
    return this.triggerGenerateTests.asObservable();
  }

  // Method for Component A to call
  initiateGenerateTests() {
    this.triggerGenerateTests.next();
  }

  updateBodySubject(bodySubject: string) {
    this.bodySubject.next(bodySubject);

    if (this.additionalContextSubject.getValue()) {
      const additionalContext = this.additionalContextSubject.getValue();

      let refinedStory = bodySubject;

      if (!this.notesAdded) {
        refinedStory += '\n\nNotes:\n';
        this.notesAdded = true;
      }

      // Update the refined story string with the body and additional context
      refinedStory = refinedStory.split('\n\nNotes:\n')[0] + '\n\nNotes:\n' + additionalContext;

      this.updateRefinedStoryString(refinedStory);
    } else {
      this.updateRefinedStoryString(bodySubject);
    }
  }

  updateSelectedModel(selectedModel: string) {
    this.selectedModel.next(selectedModel);
  }

  updateTemplates(templates: any[]) {
    console.log('templates' + this.templates.getValue())
    this.templates.next(templates);
  }

  updateSelectedTemplate(selectedTemplate: string) {
    this.selectedTemplate.next(selectedTemplate);
  }

  updateRetrievedNotes(notes: Note[]) {
    this.retrievedNotes.next(notes);
  }

  updateProjectId(projectId: string) {
    this.resetAll();
    this.projectId.next(projectId);
  }

  updateProjectLanguage(language: string) {
    this.projectLanguage.next(language);
  }

  updateSelectedTestStyle(selectedTestStyle: string) {
    this.selectedTestStyle.next(selectedTestStyle);
  }

  updateIstruzioniScriviTest(istruzioniScriviTest: string) {
    this.istruzioniScriviTest.next(istruzioniScriviTest);
  }

  private getCurrentDateString(): string {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    return `${day}_${month}_${year}`;
  }

  private updateDateInFileName(fileName: string): string {
    const dateString = this.getCurrentDateString();
    // Regular expression to match the date pattern
    const datePattern = /\d{2}_\d{2}_\d{4}/;

    if (datePattern.test(fileName)) {
      // If a date exists, replace it
      return fileName.replace(datePattern, dateString);
    } else {
      // If no date exists, add it before the file extension or at the end
      const parts = fileName.split('.');
      if (parts.length > 1) {
        const extension = parts.pop();
        return `${parts.join('.')}_${dateString}.${extension}`;
      }
      return `${fileName}_${dateString}`;
    }
  }

  updateFileName(fileName: string) {
    if (fileName === '') {
      this.fileNameSubject.next(fileName);
      this.updateTestFileName(fileName);
    } else {
      const baseFileName = this.removeExtensionAndSuffix(fileName, 'Story');
      const updatedFileName = this.addSuffixIfMissing(baseFileName, '_Story');
      const fileNameWithDate = this.updateDateInFileName(updatedFileName);
      this.fileNameSubject.next(fileNameWithDate);
      this.updateTestFileName(baseFileName);
    }
  }

  updateTestFileName(fileName: string) {
    if (fileName === '') {
      this.testFileNameSubject.next(fileName);
    } else {
      const baseFileName = this.removeExtensionAndSuffix(fileName, 'Tests');
      const updatedFileName = this.addSuffixIfMissing(baseFileName, '_Tests');
      const fileNameWithDate = this.updateDateInFileName(updatedFileName);
      this.testFileNameSubject.next(fileNameWithDate);
    }
  }

  private removeExtensionAndSuffix(fileName: string, suffix: string): string {
    // Remove file extension
    const nameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    // Remove suffix if it exists
    const suffixRegex = new RegExp(`${suffix}$`);
    return nameWithoutExtension.replace(suffixRegex, '');
  }

  private addSuffixIfMissing(fileName: string, suffix: string): string {
    return fileName.endsWith(suffix) ? fileName : `${fileName}${suffix}`;
  }

  updateRefinedStory(refinedStory: string[]) {
    this.refinedStorySubject.next(refinedStory);
    this.updateRefinedStoryString(this.refinedStorySubject.value.join(''))
  }

  updateRefinedStoryString(refinedStory: string) {
    this.refinedStoryStringSubject.next(refinedStory);
  }

  updateAdditionalContext(additionalContext: string) {
    this.additionalContextSubject.next(additionalContext);

    const refinedStoryValue = this.refinedStoryStringSubject.getValue();
    if (refinedStoryValue) {
      let refinedStory = refinedStoryValue;

      if (!this.notesAdded) {
        refinedStory += '\n\nNotes:\n';
        this.notesAdded = true;
      }

      // Update the refined story string with the new additional context
      refinedStory = refinedStory.split('\n\nNotes:\n')[0] + '\n\nNotes:\n' + additionalContext;

      this.updateRefinedStoryString(refinedStory);
    }
  }

  checkIntegrity(generatedTests: any[]) {
    const validTests = generatedTests.filter(test =>
      test['Test Case ID'] &&
      test['Title'] &&
      test['Objective'] &&
      test['Preconditions'] &&
      test['Steps'] &&
      test['Postcondition']
    );

    const removedTests = generatedTests.length - validTests.length;
    if (removedTests > 0) {
      console.log(`Removed ${removedTests} test(s) due to missing required fields.`);
    }

    return validTests;
  }

  updateGeneratedTests(tests: any[]) {
    this.generatedTestsSubject.next(tests);
    if (tests.length > 0) {
      this.columnsSubject.next(Object.keys(tests[0]));
    }
  }

  getGeneratedTests(): any[] {
    return this.generatedTestsSubject.getValue();
  }

  getColumns(): string[] {
    return this.columnsSubject.getValue();
  }

}
