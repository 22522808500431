import {Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { StatoSessioneService } from 'app/shared/services/stato-sessione.service';
import {ApiService} from '../../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import {merge, Observable, Subject, Subscription} from 'rxjs';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr'; // Import Subscription
import { ProgettiService, ProjectDetails } from '../../../shared/services/progetti.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import {AuthService} from '../../../shared/auth/auth.service';
import {DownloaderComponent} from './downloader/downloader.component';


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, OnDestroy {
  @ViewChild('stepTesting') testing: ElementRef;
  private destroy$: Subject<void> = new Subject<void>();
  uid: string | null = null;
  selectedTestIndex: number | null = null;
  azione: string;
  hasSubtable = false; // flag to check if the template has a subtable
  allSubTablesExpanded = false;
  flgAppendTests = false;
  models: string[];
  testStyle: string;
  templates: any[];
  templateNames: any[];
  indicazioniRigeneraSingoloTest: string;
  istruzioniScriviTest: string;
  testFileName: string;
  storyFileName: string;
  selectedModel: string;
  selectedTemplate: string;
  isCollapsed = true;
  rawStoryString: string;
  refinedStoryString: string; // questa qui è il contenuto della storia rifinita in una stringa
  add_notes: string;
  generatedTests: any[] = []; // To store the generated test cases
  columns: string[] = [];
  testCount = 0;
  @ViewChild('testFileInputHtml') fileInputHtml: ElementRef<HTMLInputElement>;
  i: number;
  selectedTest: any;
  selectedTestCopy: any; // A deep copy of the selected test
  projectId: string;
  projectLanguage = 'en';
  projectDetails: ProjectDetails;
  private routeSubscription: Subscription;
  elapsedTime$: Observable<string>;
  isEditMode = false;
  selectedCount = 0;
  selectedTests: any[] = []; // Array to store selected tests
  numTestsToGenerate = 1;  // Default value


  constructor(private apiService: ApiService, private sessioneService: StatoSessioneService, private modalService: NgbModal,
              private route: ActivatedRoute, public spinnerService: SpinnerService, private toastr: ToastrService,
              private progettiService: ProgettiService, private authService: AuthService,
              private downloader: DownloaderComponent
  ) {}

  ngOnInit(): void {
    this.initializeData();
    this.setupSubscriptions();
    console.log('Templates in ngOnInit:', this.templates);
  }

  private initializeData(): void {
    this.testFileName = '';
    this.istruzioniScriviTest = '';
    this.models = ['Haiku', 'Sonnet', 'Sonnet 3.5', 'Omni', 'Gemini Flash', 'Gemini Pro'];
    this.loadProjectDetails();
    this.checkForSubtable();
  }

  private setupSubscriptions(): void {
    const subscriptions = [
      this.sessioneService.refinedStoryString$.pipe(tap(refinedStoryString => this.refinedStoryString = refinedStoryString)),
      this.sessioneService.selectedTemplate$.pipe(
        tap(template => {
          this.selectedTemplate = template;
          this.checkForSubtable();
        })
      ),      this.sessioneService.templates$.pipe(
        tap(templates => {
          this.templates = templates;
          this.templateNames = this.templates.map(template => template.templateName);
          if (!this.selectedTemplate && this.templateNames.length > 0) {
            this.sessioneService.updateSelectedTemplate(this.templateNames[0]);
          }
          this.checkForSubtable();
        })
      ),
      this.sessioneService.bodySubject$.pipe(tap(body => this.rawStoryString = body)),
      this.sessioneService.additionalContext$.pipe(tap(add_notes => this.add_notes = add_notes)),
      this.sessioneService.fileName$.pipe(tap(storyFileName => this.storyFileName = storyFileName)),
      this.sessioneService.testFileName$.pipe(tap(testFileName => this.testFileName = testFileName)),
      this.sessioneService.selectedModel$.pipe(tap(model => this.selectedModel = model)),
      this.sessioneService.projectId$.pipe(tap(projectId => this.projectId = projectId)),
      this.sessioneService.projectLanguage$.pipe(tap(lang => this.projectLanguage = lang)),
      this.sessioneService.selectedTestStyle$.pipe(tap(style => this.testStyle = style)),
      this.sessioneService.istruzioniScriviTest$.pipe(tap(instruct => this.istruzioniScriviTest = instruct)),
      this.authService.email$.pipe(tap(email => this.uid = email)),
      this.sessioneService.generatedTests$.pipe(
        tap((generatedTests: any[]) => {
          this.generatedTests = generatedTests.map((test, index) => ({
            ...test,
            ID: `TC${(index + 1).toString().padStart(2, '0')}`
          }));
          console.log(this.generatedTests);
        })
      ),
      this.sessioneService.testCount$.pipe(tap(count => this.testCount = count)),
      // New subscription for columns
      this.sessioneService.columns$.pipe(
        tap((columns: string[]) => {
          this.columns = columns;
          console.log('Columns updated:', this.columns);
        })
      )
    ];

    merge(...subscriptions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {},
        error => console.error('Error in TestComponent:', error)
      );
  }

  updateSelectedModel() {
    this.sessioneService.updateSelectedModel(this.selectedModel);
  }

  checkForSubtable(): void {
    if (this.selectedTemplate && this.templates) {
      const selectedTemplateObj = this.templates.find(template => template.templateName === this.selectedTemplate);
      if (selectedTemplateObj) {
        this.hasSubtable = this.templateHasSubtable(selectedTemplateObj);
      }
    }
  }

  templateHasSubtable(template: any): boolean {
    if (template && template.columns && Array.isArray(template.columns)) {
      return template.columns.some(column =>
        column.list && Array.isArray(column.subColumns) && column.subColumns.length > 0
      );
    }
    return false;
  }

  updateSelectedTemplate() {
    this.sessioneService.updateSelectedTemplate(this.selectedTemplate);
    this.checkForSubtable(); // Add this line to check for subtable when template is updated
  }

  updateSelectedTestStyle() {
    this.sessioneService.updateSelectedTestStyle(this.testStyle);
  }

  updateIstruzioniScriviTest() {
    this.sessioneService.updateIstruzioniScriviTest(this.istruzioniScriviTest);
  }

  updateNumTestsToGenerate() {
    console.log('Number of tests to generate:', this.numTestsToGenerate);
  }

  openGeneraTestsModal(azione, modalContent: any) {
    this.azione = azione
    this.modalService.dismissAll();
    this.modalService.open(modalContent, { size: 'l', scrollable: true });
  }

  determineColumns() {
    if (this.generatedTests.length > 0) {
      this.columns = Object.keys(this.generatedTests[0]);
      console.log('DEBUG: ' + this.columns);
    }
  }

  initializeSubTableStates() {
    this.generatedTests.forEach(test => {
      this.columns.forEach(column => {
        if (this.isSubTable(test[column])) {
          test[column + '_expanded'] = false;
        }
      });
    });
  }

  toggleAllSubTables(): void {
    this.allSubTablesExpanded = !this.allSubTablesExpanded;
  }

  getSubTableColumns(subTable: any): string[] {
    return subTable.headers || [];
  }

  getSubTableRows(subTable: any): any[] {
    if (subTable.rows) {
      return subTable.rows;
    } else if (Array.isArray(subTable)) {
      return subTable;
    }
    return [];
  }

  toggleSubTable(test: any, column: string) {
    test[column + '_expanded'] = !test[column + '_expanded'];
  }

  updateContent(test: any, column: string, event: Event) {
    const target = event.target as HTMLElement;
    test[column] = target.innerHTML;
  }

  salvaTests() {
    this.spinnerService.show();
    const selectedTemplateColumns = this.templates.find(template => template.templateName === this.selectedTemplate)?.columns || [];

    console.log('Calling saveTest function');
    this.apiService.saveTest(
      this.projectId,
      this.uid,
      this.selectedModel,
      selectedTemplateColumns,
      this.refinedStoryString,
      this.generatedTests,
      this.istruzioniScriviTest
    ).subscribe(
      response => {
        console.log('Test saved:', response);
        this.spinnerService.hide()
        alert('Test saved successfully!');
      },
      error => {
        console.error('Error saving test:', error);
        this.spinnerService.hide()
        alert('Failed to save test. Please try again.');
      }
    );
  }

  // TEST METHODS
  generateTests() {
    this.modalService.dismissAll();
    this.spinnerService.show()
    const selectedTemplateColumns = this.templates.find(template => template.templateName === this.selectedTemplate)?.columns || [];
    const selectedTemplateDesc = this.templates.find(template => template.templateName === this.selectedTemplate)?.templateDescription || [];
    this.apiService.generateTestCases(this.projectId, this.uid, this.selectedModel, this.projectLanguage, selectedTemplateDesc, selectedTemplateColumns, this.refinedStoryString, this.testStyle, this.istruzioniScriviTest)
      .subscribe(
        response => {
          this.spinnerService.hide()
          this.sessioneService.updateGeneratedTests(response);
          // The columns are now automatically determined and stored in the service
          this.columns = this.sessioneService.getColumns();
          this.initializeSubTableStates();
        },
        error => {
          this.spinnerService.hide();
        }
      );
  }

  loadProjectLanguage() {
    this.progettiService.getProjectLanguage(this.projectId).subscribe(
      language => {
        if (language) {
          this.projectLanguage = language;
          console.log('Project language:', language);
        }
      },
      error => console.error('Error loading project language:', error)
    );
  }

  loadProjectDetails() {
    this.route.params.pipe(
      switchMap(params => this.progettiService.getProjectDetails(params.project_id))
    ).subscribe(
      details => {
        if (details) {
          this.projectDetails = details;
          console.log('Project details:', details);
        }
      },
      error => console.error('Error loading project details:', error)
    );
  }

  openGeneraAltriTestsModal(azione, modalContent: any) {
    this.azione = azione
    this.modalService.dismissAll();
    this.modalService.open(modalContent, { size: 'l', scrollable: true });
  }

  openGeneraAltriTestsModal2(modalContent: any) {
    if (this.refinedStoryString) {
      this.modalService.dismissAll();
      this.flgAppendTests = true
      this.generateMoreTests();
      this.selectedTestCopy = []
      this.modalService.open(modalContent, {size: 'xl', scrollable: true});
    } else {
      this.toastr.error('Non hai caricato una storia!', 'Error');
    }
  }

  generateMoreTests() {
    // inizio feedback d'attesa utente
    this.spinnerService.show()

    const selectedTemplateColumns = this.templates.find(template => template.templateName === this.selectedTemplate)?.columns || [];
    const selectedTemplateDesc = this.templates.find(template => template.templateName === this.selectedTemplate)?.templateDescription || [];
    this.apiService.generateMoreTests(this.refinedStoryString, this.generatedTests, this.selectedModel, this.projectLanguage, selectedTemplateDesc, selectedTemplateColumns, this.istruzioniScriviTest, this.numTestsToGenerate.toString())
      .subscribe(
        response => {
          this.spinnerService.hide();
          this.selectedTestCopy = response
          // this.istruzioniScriviTest = ''
          // console.log('output test generati in append: ' + response)
          // this.sessioneService.updateGeneratedTests(response);
        },
        error => {
          this.spinnerService.hide();
        }
      );
  }

  onTestFileSelected(typeFile: string): void {
    const input = this.fileInputHtml.nativeElement;
    this.testFileName = '';

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.testFileName = file.name;
      this.sessioneService.updateFileName(file.name);
      this.uploadFileToActivityTest(file);
    }
  }

  uploadFileToActivityTest(file: File): void {
    if (!this.templates || this.templates.length === 0) {
      this.toastr.warning('Templates are not loaded yet. Please try again in a moment.', 'Templates Not Ready');
      return;
    }
    this.spinnerService.show();
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const htmlContent = e.target.result;
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      // Extract context
      const contextElement = doc.getElementById('contextContainer');
      if (contextElement) {
        this.refinedStoryString = contextElement.textContent || '';
        this.sessioneService.updateRefinedStoryString(this.refinedStoryString);
      }

      // Extract metadata and match template
      const metadataScript = doc.getElementById('metadata');
      if (metadataScript) {
        try {
          const metadata = JSON.parse(metadataScript.textContent || '{}');
          console.log('Parsed metadata:', metadata);

          if (!metadata.selectedTemplateColumns || !Array.isArray(metadata.selectedTemplateColumns)) {
            console.error('Invalid or missing selectedTemplateColumns in metadata');
            this.toastr.error('Invalid metadata format in the uploaded file.', 'Metadata Error');
            this.spinnerService.hide();
            return;
          }

          const uploadedColumns = metadata.selectedTemplateColumns.map(col => col.name || col);
          console.log('Uploaded columns:', uploadedColumns);

          console.log('Available templates:', this.templates);
          this.templates.forEach(template => {
            console.log(`Template: ${template.templateName}, Columns:`, template.columns);
          });

          const normalizeColumns = (cols) => cols.map(col => {
            if (typeof col === 'string') {
              return col.toLowerCase();
            } else if (col && typeof col.name === 'string') {
              return col.name.toLowerCase();
            } else {
              console.warn('Unexpected column format:', col);
              this.spinnerService.hide();
              return String(col).toLowerCase();
            }
          });

          const matchingTemplate = this.templates.find(template =>
            normalizeColumns(template.columns).length === normalizeColumns(uploadedColumns).length &&
            normalizeColumns(template.columns).every(col => normalizeColumns(uploadedColumns).includes(col))
          );

          if (matchingTemplate) {
            this.selectedTemplate = matchingTemplate.templateName;
            this.sessioneService.updateSelectedTemplate(this.selectedTemplate);
            console.log('Matching template found:', this.selectedTemplate);

            // Proceed with file upload only if a matching template is found
            this.proceedWithFileUpload(file);
          } else {
            this.handleNoMatchingTemplate(uploadedColumns);
          }
        } catch (error) {
          console.error('Error parsing metadata:', error);
          this.toastr.error('Failed to parse metadata from the uploaded file.', 'Metadata Error');
        }
      } else {
        console.error('Metadata script not found in the uploaded file');
        this.toastr.error('Metadata not found in the uploaded file.', 'Metadata Error');
      }
    };
    reader.readAsText(file);
    this.spinnerService.hide();
  }

  private handleNoMatchingTemplate(uploadedColumns: string[]): void {
    console.log('No matching template found for uploaded columns:', uploadedColumns);
    this.toastr.error('There is no matching suitable template for the uploaded file.', 'Template Mismatch');

    // Display the template content instead of the table
    this.showTemplateContent(uploadedColumns);
  }

  private showTemplateContent(uploadedColumns: string[]): void {
    const templateContent = `
    <h3>No Matching Template Found</h3>
    <p>The uploaded file contains the following columns:</p>
    <ul>
      ${uploadedColumns.map(col => `<li>${col}</li>`).join('')}
    </ul>
    <p>Please ensure you are using a compatible template or contact support for assistance.</p>
  `;

    // Update the view with the template content
    // You'll need to add a div in your HTML template to display this content
    const contentContainer = document.getElementById('templateContentContainer');
    if (contentContainer) {
      contentContainer.innerHTML = templateContent;
      contentContainer.style.display = 'block';
    } else {
      console.error('Template content container not found in the DOM');
    }

    // Hide the table if it's currently displayed
    const tableContainer = document.querySelector('.test-table-container');
    if (tableContainer) {
      tableContainer.classList.add('d-none');
    }
  }

  private proceedWithFileUpload(file: File): void {
    this.apiService.uploadFileTests(file).subscribe(
      response => {
        this.sessioneService.updateGeneratedTests(response);
        this.determineColumns();
        this.initializeSubTableStates();

        // Show the table and hide the template content
        const tableContainer = document.querySelector('.test-table-container');
        if (tableContainer) {
          tableContainer.classList.remove('d-none');
        }
        const contentContainer = document.getElementById('templateContentContainer');
        if (contentContainer) {
          contentContainer.style.display = 'none';
        }
      },
      error => {
        console.error('Error uploading file:', error);
        this.toastr.error('Failed to upload file. Please try again.', 'Upload Error');
      }
    );
  }

  clearFile(): void {
    this.testFileName = '';
    this.sessioneService.updateFileName('');
  }

  openRigeneraSingoloTestModal(test: any, modalContent: any) {
    this.flgAppendTests = false;
    this.selectedTest = test;
    this.selectedTestCopy = [cloneDeep(test)]; // Wrap the clone in an array
    this.modalService.open(modalContent, { size: 'xl', scrollable: true });
  }

  openRigeneraMultipliTestModal(modalContent: any) {
    this.flgAppendTests = false;
    this.selectedTestCopy = this.selectedTests.map(test => cloneDeep(test));
    this.modalService.open(modalContent, { size: 'xl', scrollable: true });
  }

  rigeneraSingoloTest(indicazioni: boolean) {
    // inizio feedback d'attesa utente
    this.spinnerService.show()
    const selectedTemplateColumns = this.templates.find(template => template.templateName === this.selectedTemplate)?.columns || [];
    const selectedTemplateDesc = this.templates.find(template => template.templateName === this.selectedTemplate)?.templateDescription || [];
    if (indicazioni) {
      this.apiService.regenerateSingoloTest(this.selectedModel, this.projectLanguage, selectedTemplateDesc, selectedTemplateColumns, this.selectedTestCopy, this.refinedStoryString, this.indicazioniRigeneraSingoloTest)
        .subscribe(
          (regeneratedTests: any[]) => {
            // 2. Update selectedTestCopy with the regenerated tests
            this.selectedTestCopy = regeneratedTests;
            this.spinnerService.hide();
          },
          (error) => {
            // Handle errors from the API call (e.g., display an error message)
            console.error('Error regenerating test:', error);
            this.spinnerService.hide();
          }
        );
    } else {
        this.apiService.regenerateSingoloTest(this.selectedModel, this.projectLanguage, selectedTemplateDesc, selectedTemplateColumns, this.selectedTestCopy, this.refinedStoryString)
          .subscribe(
            (regeneratedTests: any[]) => {
              // 2. Update selectedTestCopy with the regenerated tests
              this.selectedTestCopy = regeneratedTests;
              this.spinnerService.hide();
            },
            (error) => {
              // Handle errors from the API call (e.g., display an error message)
              console.error('Error regenerating test:', error);
              this.spinnerService.hide();
            }
          );
    }
  }

  openScomponiTestModal(test: any, modalContent: any) {
    this.flgAppendTests = false;
    this.selectedTest = test;
    this.selectedTestCopy = [cloneDeep(test)]; // Wrap the clone in an array
    // Call rigeneraSingoloTest before opening the modal
    this.rigeneraSingoloTest(false);
    this.modalService.open(modalContent, { size: 'xl', scrollable: true });
  }

  deleteTestRigenerato(index: number): void {
    if (confirm('Are you sure you want to delete this test case?')) {

      // Find the index of the test to delete in the selectedTestCopy array (which is a deep copy)
      if (index > -1) {
        // Remove the test from the copied array
        this.selectedTestCopy.splice(index, 1);
      }
    }
  }

  deleteTest(index: number): void {
    if (confirm('Are you sure you want to delete this test case?')) {
      if (index > -1) {
        this.generatedTests.splice(index, 1);
        this.modalService.dismissAll(); // Close any open modals related to the deleted test
      }
    }
    this.sessioneService.updateGeneratedTests(this.generatedTests)
  }

  saveRigeneratoTest(): void {
    if (this.selectedTestCopy.length === 0) {
      console.warn('No tests to save');
      return;
    }

    // Create a map of original indices
    const originalIndices = new Map();

    if (this.selectedTests.length > 0) {
      // Multiple tests scenario
      this.selectedTests.forEach(test => {
        const index = this.generatedTests.indexOf(test);
        if (index > -1) {
          originalIndices.set(test, index);
        }
      });

      // Remove selected tests from generatedTests
      this.generatedTests = this.generatedTests.filter(test => !this.selectedTests.includes(test));
    } else if (this.selectedTest) {
      // Single test scenario
      const index = this.generatedTests.indexOf(this.selectedTest);
      if (index > -1) {
        originalIndices.set(this.selectedTest, index);
        this.generatedTests.splice(index, 1);
      }
    }

    // Insert regenerated tests back into their original positions
    this.selectedTestCopy.forEach((test, i) => {
      const originalTest = this.selectedTests[i] || this.selectedTest;
      const originalIndex = originalIndices.get(originalTest);

      // Remove the 'isSelected' property before inserting
      const { isSelected, ...cleanedTest } = test;

      if (originalIndex !== undefined) {
        this.generatedTests.splice(originalIndex, 0, cleanedTest);
      } else {
        // If for some reason we don't have the original index, append to the end
        this.generatedTests.push(cleanedTest);
      }
    });

    // Update the session service
    this.sessioneService.updateGeneratedTests(this.generatedTests);

    // Reset selection state
    this.resetSelections();

    // Close the modal
    this.modalService.dismissAll();
  }

  resetSelections(): void {
    this.generatedTests.forEach(test => {
      if (test.hasOwnProperty('isSelected')) {
        test.isSelected = false;
      }
    });
    this.selectedTests = [];
    this.selectedTest = null;
    this.selectedTestCopy = [];
    this.selectedCount = 0;
    this.isEditMode = false;
  }


  appendTests(): void {
    // Append the tests in selectedTestCopy to generatedTests
    this.generatedTests.push(...this.selectedTestCopy);
    // Update the session service
    this.sessioneService.updateGeneratedTests(this.generatedTests);

    // Close the modal
    this.modalService.dismissAll();
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
    if (!this.isEditMode) {
      this.resetSelections();
    }
  }

  onSelectionChange(test: any) {
    if (test.isSelected) {
      this.selectedCount++;
      this.selectedTests.push(test);
    } else {
      this.selectedCount--;
      this.selectedTests = this.selectedTests.filter(t => t !== test);
    }

    // Log the currently selected tests
    console.log('Currently selected tests:', this.selectedTests);
  }

  getSubTableRowCount(subTableData: any): number {
    if (Array.isArray(subTableData)) {
      return subTableData.length;
    } else if (subTableData && subTableData.rows && Array.isArray(subTableData.rows)) {
      return subTableData.rows.length;
    }
    return 0;
  }

  openEditModalTest(test: any, index: number, modalContent: any) {
    this.selectedTestIndex = index;
    this.selectedTest = JSON.parse(JSON.stringify(test)); // Create a deep copy
    this.modalService.open(modalContent, { ariaLabelledBy: 'testModalLabel', size: 'xl' });
  }

  isSimpleInput(value: any): boolean {
    return typeof value === 'string' || typeof value === 'number';
  }

  isLongText(value: any): boolean {
    return typeof value === 'string' && value.length > 100;
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  isSubTable(value: any): boolean {
    return value && typeof value === 'object' && 'headers' in value && 'rows' in value;
  }

  getInputType(value: any): string {
    return typeof value === 'number' ? 'number' : 'text';
  }

  addArrayItem(column: string): void {
    if (!Array.isArray(this.selectedTest[column])) {
      this.selectedTest[column] = [];
    }
    this.selectedTest[column].push('');
  }

  removeArrayItem(column: string, index: number): void {
    this.selectedTest[column].splice(index, 1);
  }

  addSubTableRow(column: string): void {
    const headers = this.getSubTableHeaders(this.selectedTest[column]);
    const newRow = {};
    headers.forEach(header => newRow[header] = '');

    if (Array.isArray(this.selectedTest[column])) {
      this.selectedTest[column].push(newRow);
    } else if (this.selectedTest[column].rows) {
      this.selectedTest[column].rows.push(newRow);
    } else {
      this.selectedTest[column] = [newRow];
    }
  }

  removeSubTableRow(column: string, index: number): void {
    if (Array.isArray(this.selectedTest[column])) {
      this.selectedTest[column].splice(index, 1);
    } else if (this.selectedTest[column].rows) {
      this.selectedTest[column].rows.splice(index, 1);
    }
  }

  saveEditedTest(): void {
    if (this.selectedTestIndex !== null) {
      // Update the original array with the edited data
      this.generatedTests[this.selectedTestIndex] = JSON.parse(JSON.stringify(this.selectedTest));
      this.sessioneService.updateGeneratedTests(this.generatedTests);

      // Reset the selected test index
      this.selectedTestIndex = null;
    }
    this.modalService.dismissAll();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  getSubTableHeaders(subTable: any): string[] {
    if (subTable.headers) {
      return subTable.headers;
    } else if (Array.isArray(subTable) && subTable.length > 0) {
      return Object.keys(subTable[0]);
    }
    return [];
  }

  downloadGeneratedTests() {
    this.downloader.downloadGeneratedTests(this.testFileName, this.generatedTests, this.columns,
      this.selectedTemplate, this.templates, this.refinedStoryString, this.projectId, this.uid, this.selectedModel,
      this.projectLanguage, this.selectedTemplate, this.istruzioniScriviTest);
  }

  downloadCSVGeneratedTests() {
    this.downloader.downloadCSVGeneratedTests(this.testFileName, this.generatedTests, this.columns);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  downloadStory() {
    const cleanedFileName = this.removeTestBeforeStory(this.storyFileName);
    this.downloader.downloadStoryFile(this.refinedStoryString, cleanedFileName);
  }

  private removeTestBeforeStory(fileName: string): string {
    // This regex looks for 'Test' followed by 'Story', optionally separated by non-word characters
    const testBeforeStoryRegex = /Tests\W*Story/;

    if (testBeforeStoryRegex.test(fileName)) {
      // If 'Test' is found before 'Story', remove 'Test' and any non-word characters between them
      return fileName.replace(/Tests\W*(?=Story)/, '');
    }

    // If 'Test' is not found before 'Story', return the original filename
    return fileName;
  }
}


