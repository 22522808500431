import { NgModule } from '@angular/core';
import { CommonModule, NgSwitch } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbModalModule, NgbTimepickerModule, NgbTooltipModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ProjectsRoutingModule } from './projects-routing.module';
import { ArchwizardModule } from 'angular-archwizard';
// import { CustomFormsModule } from 'ngx-custom-validators';
// import { UiSwitchModule } from 'ngx-ui-switch';
// import { TagInputModule } from 'ngx-chips';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuillModule } from 'ngx-quill';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { TranslateModule } from '@ngx-translate/core';
// import { SharedModule } from 'app/shared/shared.module';
// import { MatchHeightModule } from 'app/shared/directives/match-height.directive';

import { StoryComponent } from './story/story.component';
import { TestComponent } from './test/test.component';
import { ConfigurationComponent } from './configuration/configuration.component';

import { NotesComponent } from './story/notes/notes.component';
import { CoverageComponent } from './test/coverage/coverage.component';
import { StoryViewerComponent } from './story/story-viewer/story-viewer.component';
import { ColumnsComponent } from './configuration/columns/columns.component';
import { ManageComponent } from './configuration/manage-columns/manage.component';
import { DownloaderComponent } from './test/downloader/downloader.component';
import {DragDropModule} from '@angular/cdk/drag-drop';


@NgModule({
    imports: [
        CommonModule,
        ProjectsRoutingModule,
        // TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModalModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        NgbDatepickerModule,
        ArchwizardModule,
        FormsModule,
        NgbTooltipModule,
        // SharedModule,
        NgxSpinnerModule,
        NgSelectModule,
        NgbModule,
        QuillModule.forRoot(),
        ClipboardModule,
        // CustomFormsModule,
        // UiSwitchModule,
        // TagInputModule,
        // MatchHeightModule,
        HttpClientModule,
        DragDropModule
    ],
  providers: [
    DownloaderComponent,
  ],
  exports: [],
  declarations: [
    StoryComponent,
    TestComponent,
    ConfigurationComponent,
    NotesComponent,
    CoverageComponent,
    StoryViewerComponent,
    ColumnsComponent,
    ManageComponent,
  ],
})
export class ProjectsModule { }
