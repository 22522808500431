import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {StatoSessioneService} from '../../../../shared/services/stato-sessione.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthService, UserRole} from '../../../../shared/auth/auth.service';

export interface Note {
  id: string;
  date: string;
  content: string;
  selectionOrder: number | null;
  expanded: boolean;
  isEditing: boolean;
  editedContent: string;
  similarity: number | null;
}

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  @ViewChild('fileAddNotes') fileAddNotes: ElementRef<HTMLInputElement>;

  isNotesCollapsed = true;
  retrievedNotes: Note[] = [];
  selectionCounter = 0;
  refreshNotes = false
  projectId: string;
  additionalNotes: string;
  story: string;
  additionalNotesFileName: string;
  addNotesUploadCompleted = false;

  private roleSubscription: Subscription;
  isQAnalystOrManager = false;

  timing = false;
  startTime: number;
  intervalId: any;
  elapsedTime = 0; // Time in milliseconds


  constructor(private apiService: ApiService, private sessioneService: StatoSessioneService,
              private modalService: NgbModal, private toastr: ToastrService,
              private spinner: NgxSpinnerService, private route: ActivatedRoute, private authService: AuthService) { }

  ngOnInit(): void {

    this.sessioneService.projectId$.subscribe(projectId => {
      this.projectId = this.route.snapshot.params.project_id;
      this.refreshNotes = true
    });

    this.sessioneService.additionalContext$.subscribe(addNotes => {
      this.additionalNotes = addNotes;
    });

    this.sessioneService.refinedStoryString$.subscribe(story => {
      this.story = story;
    });

    this.sessioneService.retrievedNotes$.subscribe(notes => {
      this.retrievedNotes = notes;
    });

    this.roleSubscription = this.authService.userRole$.subscribe((role: UserRole | null) => {
      this.isQAnalystOrManager = role === 'Admin' || role === 'Manager' || role === 'QA Analyst';
    });
  }

  onNotesChange(newNotes: string) {
    this.sessioneService.updateAdditionalContext(newNotes);
  }

  openSelezionaNotesModal(modalContent: any) {
    this.selectionCounter = 0;
    this.startSpinner();
    const retrieveNotes = () => {
      this.apiService.retrieveAllNotes(this.projectId).subscribe(
        (notes: any[]) => {
          this.retrievedNotes = notes.map(note => ({
            id: note.id,
            date: note.date,
            content: note.content,
            selectionOrder: null,
            expanded: true,
            isEditing: false,
            editedContent: '',
            similarity: null
          }));
          this.stopSpinner();
        },
        (error) => {
          console.error('Error retrieving notes:', error);
          this.stopSpinner();
        }
      );
    };

    if (this.refreshNotes || !this.retrievedNotes || this.retrievedNotes.length === 0) {
      retrieveNotes();
    }

    this.modalService.dismissAll();
    this.modalService.open(modalContent, { size: 'xl', scrollable: true });
  }


  toggleNoteSelection(note: Note) {
    if (note.selectionOrder === null) {
      // Note is being selected
      this.selectionCounter++;
      note.selectionOrder = this.selectionCounter;
    } else {
      // Note is being deselected
      note.selectionOrder = null;
      // Reorder remaining selected notes
      this.retrievedNotes
        .filter(n => n.selectionOrder !== null)
        .sort((a, b) => a.selectionOrder! - b.selectionOrder!)
        .forEach((n, index) => n.selectionOrder = index + 1);
      this.selectionCounter = this.retrievedNotes.filter(n => n.selectionOrder !== null).length;
    }
  }

  selectNotes() {
    const selectedNotes = this.retrievedNotes
      .filter(note => note.selectionOrder !== null)
      .sort((a, b) => a.selectionOrder! - b.selectionOrder!);

    let concatenatedContent = '';

    selectedNotes.forEach((note, index) => {
      // concatenatedContent += `Note ${index + 1} (ID: ${note.id}, Date: ${note.date}):\n${note.content}\n\n`;
      concatenatedContent += `${note.content}\n\n`;
    });

    if (concatenatedContent) {
      console.log('Concatenated notes content:');
      console.log(concatenatedContent);
      // You can also assign this to a property if you want to use it in the template
      this.sessioneService.updateAdditionalContext(concatenatedContent);
    } else {
      console.log('No notes selected');
    }
    this.modalService.dismissAll();
  }

  salvaNotes() {
    this.startSpinner();
    this.apiService.saveNotes(this.projectId, this.additionalNotes)
      .subscribe(response => {
        this.toastr.success('Note saved successfully!', 'Success');
        this.stopSpinner();
        this.refreshNotes = true
      }, error => {
        this.stopSpinner();
        console.error('Error saving note:', error);
        this.toastr.error('Error while saving notes!', 'Error');
      });

  }

  editNote(note: Note) {
    note.isEditing = true;
    note.editedContent = note.content;
  }

  saveNoteChanges(note: Note) {
    this.startSpinner();
    this.apiService.updateNote(this.projectId, note.id, note.editedContent).subscribe(
      updatedNote => {
        Object.assign(note, updatedNote);
        note.isEditing = false;
        this.stopSpinner();
        this.toastr.success('Note saved successfully!', 'Success');
      },
      error => {
        console.error('Error updating note:', error)
        this.stopSpinner();
        this.toastr.error('Error while saving notes!', 'Error');

      }
    );
  }

  cancelNoteEdit(note: Note) {
    note.isEditing = false;
    note.editedContent = note.content;
  }

  deleteNote(noteId: string) {
    if (confirm('Are you sure you want to delete this note?')) {

      this.apiService.deleteNote(this.projectId, noteId).subscribe(
        () => {
          this.retrievedNotes = this.retrievedNotes.filter(note => note.id !== noteId);
        },
        error => console.error('Error deleting note:', error)
      );

    }
  }

  retrieveSimilarNotes() {
    if ( this.story ) {
      this.startSpinner();
      this.apiService.retrieveSimilarNotes(this.projectId, this.story).subscribe(
        (notes: any[]) => {
          const formattedNotes = notes.map(note => ({
            id: note.id,
            date: note.date,
            content: note.content,
            selectionOrder: null,
            expanded: true,
            isEditing: false,
            editedContent: '',
            similarity: note.similarity
          }));
          this.sessioneService.updateRetrievedNotes(formattedNotes);
          this.stopSpinner();
        },
        (error) => {
          console.error('Error retrieving similar notes:', error);
          this.stopSpinner();
        }
      );
    } else {
      this.toastr.error('Non hai caricato una storia!', 'Error');
    }
  }

  onAdditionalNotesFileSelected(): void {
    const input = this.fileAddNotes.nativeElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.additionalNotesFileName = file.name;
      this.apiService.uploadFile(file).subscribe(
        (response: { filename: string, contents: string }) => {
          this.sessioneService.updateAdditionalContext(response.contents)
          this.addNotesUploadCompleted = true
        },
        (error: any) => {
          console.error('There was an error uploading the Add Notes document! API ERROR', error);
        }
      );

    } else {
      console.error('There was an error uploading the Add Notes document!');
    }
  }

  clearAdditionalNotesFile(): void {
    this.additionalNotesFileName = '';
  }

  updateElapsedTime() {
    const now = Date.now();
    this.elapsedTime = Math.floor((now - this.startTime) / 1000); // Convert to seconds
  }

  startSpinner() {
    this.spinner.show(undefined, {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: true
    });
    this.timing = true;
    this.startTime = Date.now(); // Capture start time
    this.intervalId = setInterval(() => {
      this.updateElapsedTime();
    }, 1000); // Update every 1000 milliseconds (1 second)
  }

  stopSpinner() {
    this.spinner.hide();
    this.timing = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
