import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import {switchMap, map, tap} from 'rxjs/operators';
import firebase from 'firebase/app';

export type UserRole = 'Admin' | 'Tester' | 'Developer' | 'Manager' | 'QA Analyst';

export interface UserData {
  uid: string;
  role: UserRole;
  assignedProjects: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  public isLoggedIn$: Observable<boolean> = this.loggedIn.asObservable();

  private userRole = new BehaviorSubject<UserRole | null>(null);
  public userRole$ = this.userRole.asObservable();

  private email = new BehaviorSubject<string>('');
  email$ = this.email.asObservable();

  private ruolo = new BehaviorSubject<string>('');
  ruolo$ = this.ruolo.asObservable();

  private tokenSubject = new BehaviorSubject<string | null>(null);
  public token$ = this.tokenSubject.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private router: Router,
  ) {
    this.initAuthState();
  }

  private initAuthState() {
    this.afAuth.authState.pipe(
      switchMap(user => {
        if (user && user.email) {
          this.loggedIn.next(true);
          this.email.next(user.email);
          return this.firestore.doc<UserData>(`users/${user.email}`).valueChanges();
        } else {
          this.loggedIn.next(false);
          this.email.next('');
          this.userRole.next(null);
          this.tokenSubject.next(null);
          return of(null);
        }
      }),
      tap(userData => {
        if (userData) {
          this.userRole.next(userData.role);
          this.ruolo.next(userData.role);
        }
      })
    ).subscribe();

    this.afAuth.idToken.subscribe(token => {
      this.tokenSubject.next(token);
      if (token) {
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
    });
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  async signinUser(email: string, password: string): Promise<boolean> {
    try {
      const userCredential = await this.afAuth.signInWithEmailAndPassword(email, password);
      const user = userCredential.user;

      if (user && user.email) {
        const userDoc = await this.firestore.doc<UserData>(`users/${user.email}`).get().toPromise();
        const userData = userDoc?.data();

        if (userData) {
          console.log(`User signed in successfully. Role: ${userData.role}`);
          this.userRole.next(userData.role);
        } else {
          console.log('User signed in successfully, but no role information found in Firestore.');
          this.userRole.next(null);
        }
      }
      this.updateUserDataService();

      return true;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  async logout(): Promise<void> {
    try {
      await this.afAuth.signOut();
      this.router.navigate(['/pages/login']);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }

  isAuthenticated(): boolean {
    return this.loggedIn.value;
  }

  hasRole(role: UserRole): Observable<boolean> {
    return this.userRole$.pipe(
      map(userRole => userRole === role)
    );
  }

  async setUserRole(uid: string, role: UserRole): Promise<void> {
    const currentUser = await this.afAuth.currentUser;
    if (!currentUser || !currentUser.email) {
      throw new Error('No authenticated user or email not available');
    }

    const currentUserDoc = await this.firestore.doc<UserData>(`users/${currentUser.email}`).get().toPromise();
    const currentUserData = currentUserDoc?.data();

    if (currentUserData?.role !== 'Admin') {
      throw new Error('Only admins can change user roles');
    }

    await this.firestore.doc(`users/${uid}`).set({ role }, { merge: true });
  }

  async createUser(email: string, password: string, role: UserRole = 'Tester'): Promise<void> {
    try {
      const currentUser = await this.afAuth.currentUser;
      if (!currentUser || !currentUser.email) {
        throw new Error('No authenticated user or email not available');
      }

      const currentUserDoc = await this.firestore.doc<UserData>(`users/${currentUser.email}`).get().toPromise();
      const currentUserData = currentUserDoc?.data();

      if (currentUserData?.role !== 'Admin') {
        throw new Error('Only admins can create new users');
      }

      // Store the current admin's email
      const adminEmail = currentUser.email;

      // Prompt for admin's password
      const adminPassword = prompt('Please enter your admin password to confirm this action:');
      if (!adminPassword) {
        throw new Error('Admin password is required to create a new user');
      }

      // Verify admin's password
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(adminEmail, adminPassword);
        await currentUser.reauthenticateWithCredential(credential);
      } catch (error) {
        alert('Admin password verification failed: wrong password');
        throw new Error('Invalid admin password. User creation aborted.');
      }

      // Create the new user
      const newUserCredential = await this.afAuth.createUserWithEmailAndPassword(email, password);
      const newUser = newUserCredential.user;

      if (newUser) {
        await this.firestore.doc(`users/${newUser.email}`).set({
          uid: newUser.email,
          email: newUser.email,
          role: role,
          assignedProjects: []
        });
      }

      // Sign out the new user
      await this.afAuth.signOut();

      // Sign back in as the admin
      await this.afAuth.signInWithEmailAndPassword(adminEmail, adminPassword);

      alert(`User created successfully: ${email}`);
    } catch (error) {
      console.error('Error in createUser:', error);
      throw error;
    }
  }

  getAllUsers(): Observable<UserData[]> {
    return this.firestore.collection<UserData>('users').valueChanges({ idField: 'uid' });
  }

  getCurrentUser(): Promise<firebase.User | null> {
    return this.afAuth.currentUser;
  }

  getUserData(userId: string): Observable<UserData | null> {
    return this.firestore.doc<UserData>(`users/${userId}`).valueChanges();
  }

  getCurrentUserData(): Observable<UserData | null> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user && user.email) {
          return this.firestore.doc<UserData>(`users/${user.email}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  updateUserDataService() {
    this.getCurrentUserData().subscribe((userData: UserData | null) => {
      if (userData) {
        // console.log(userData)
        this.email.next(userData.uid);
        this.ruolo.next(userData.role);
      } else {
        console.log('DEBUG No user data available : navbar.component.TS');
      }
    });
  }

  assignProjectToUser(userId: string, projectId: string): Promise<void> {
    return this.firestore.doc(`users/${userId}`).update({
      assignedProjects: firebase.firestore.FieldValue.arrayUnion(projectId)
    });
  }

  removeProjectFromUser(userId: string, projectId: string): Promise<void> {
    return this.firestore.doc(`users/${userId}`).update({
      assignedProjects: firebase.firestore.FieldValue.arrayRemove(projectId)
    });
  }

  getUserAssignedProjects(userId: string): Observable<string[]> {
    return this.firestore.doc<UserData>(`users/${userId}`).valueChanges().pipe(
      map(userData => userData?.assignedProjects || [])
    );
  }
}
