<ngx-spinner>
</ngx-spinner>

<div class="test-list-config">
  <h2 class="config-title">Test List Column Configuration</h2>

  <form [formGroup]="columnsForm" class="config-form">
    <div class="form-group">
      <label for="templateSelect" class="form-label">Select Template:</label>
      <select id="templateSelect" [(ngModel)]="selectedTemplate" (ngModelChange)="handleTemplateChange()" class="template-select" [ngModelOptions]="{standalone: true}">
        <option *ngFor="let template of templateOptions" [value]="template.value">{{ template.name }}</option>
      </select>
    </div>

    <div class="template-info">
      <div class="form-group">
        <label for="templateName" class="form-label">Template Name:</label>
        <input id="templateName" [(ngModel)]="templateName" class="template-name-input" placeholder="Enter template name" [ngModelOptions]="{standalone: true}" />
      </div>
      <div class="form-group">
        <label for="templateDescription" class="form-label">Template Description:</label>
        <textarea id="templateDescription" [(ngModel)]="templateDescription" class="template-description-input" placeholder="Enter template description" [ngModelOptions]="{standalone: true}"></textarea>
      </div>
    </div>

    <div class="columns-list">
      <h3 class="columns-title">Columns</h3>
      <div formArrayName="columns">
        <div *ngFor="let columnForm of columnsForm.get('columns')['controls']; let i = index" [formGroupName]="i" class="column-container" [class.list]="columnForm.get('list').value">
          <div class="column-header">
            <button *ngIf="columnForm.get('subColumns').controls.length > 0" (click)="toggleExpand(columnForm)" class="btn-toggle mr-1" [class.expanded]="columnForm.get('expanded').value">
              {{ columnForm.get('expanded').value ? '▼' : '▶' }}
            </button>
            <div class="form-group">
              <label [for]="'columnName' + i" class="form-label">Column Name:</label>
              <input [id]="'columnName' + i" formControlName="name" placeholder="Enter column name" class="column-name" />
            </div>
            <div class="form-group checkbox-group">
              <label [for]="'list' + i" class="checkbox-label">
                <input [id]="'list' + i" type="checkbox" formControlName="list" />
                <span>List</span>
              </label>
            </div>
            <button (click)="addSubColumn(i)" class="btn-add">+</button>
            <button (click)="removeColumn(i)" class="btn-remove">×</button>
          </div>
          <div class="form-group">
            <label [for]="'columnDescription' + i" class="form-label">Column Description:</label>
            <textarea [id]="'columnDescription' + i" formControlName="description" placeholder="Enter column description" rows="2" class="column-description"></textarea>
          </div>

          <div *ngIf="columnForm.get('expanded').value" class="sub-columns" formArrayName="subColumns">
            <h4 class="sub-columns-title">Sub-columns</h4>
            <div *ngFor="let subColumnForm of columnForm.get('subColumns')['controls']; let j = index" [formGroupName]="j" class="sub-column-container">
              <div class="column-header">
                <div class="form-group">
                  <label [for]="'subColumnName' + i + '-' + j" class="form-label">Sub-column Name:</label>
                  <input [id]="'subColumnName' + i + '-' + j" formControlName="name" placeholder="Enter sub-column name" class="column-name" />
                </div>
                <div class="form-group checkbox-group">
                  <label [for]="'subList' + i + '-' + j" class="checkbox-label">
                    <input [id]="'subList' + i + '-' + j" type="checkbox" formControlName="list" />
                    <span>List</span>
                  </label>
                </div>
                <button (click)="removeSubColumn(i, j)" class="btn-remove">×</button>
              </div>
              <div class="form-group">
                <label [for]="'subColumnDescription' + i + '-' + j" class="form-label">Sub-column Description:</label>
                <textarea [id]="'subColumnDescription' + i + '-' + j" formControlName="description" placeholder="Enter sub-column description" rows="2" class="column-description"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions">
      <button (click)="addColumn()" class="btn-primary">Add Column</button>
      <button (click)="previewConfiguration()" class="btn-success">Preview Configuration</button>
      <button (click)="saveConfiguration()" class="btn-success">Save Configuration</button>
    </div>
  </form>

  <div class="preview-section">
    <h3 class="preview-title">Test List Preview</h3>
    <table class="preview-table">
      <thead>
      <tr>
        <th *ngFor="let column of previewColumns">{{ column.name }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td *ngFor="let column of previewColumns" [class.nested-column]="column.subColumns">
          <ng-container *ngIf="!column.subColumns">
            {{ column.value }}
          </ng-container>
          <ng-container *ngIf="column.subColumns">
            <button (click)="togglePreviewExpand(column)" class="btn-toggle-preview">
              {{ column.expanded ? 'Hide' : 'Show' }} {{ column.subColumns.length }} {{ column.name.toLowerCase() }}
            </button>
            <table *ngIf="column.expanded" class="nested-table">
              <thead>
              <tr>
                <th *ngFor="let subColumn of column.subColumns">{{ subColumn.name }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td *ngFor="let subColumn of column.subColumns">{{ subColumn.value }}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
