<section id="ngx">
  <div class="row">
    <div class="col-sm-12">
      <div class="content-header">Scriptor for {{projectId}}</div>
    </div>
  </div>

  <!-- Configuration button -->
  <div *ngIf="isQAnalystOrManager" class="customizer">
    <a class="customizer-toggle bg-primary" (click)="openConfigurationWithConfirmation()"
       ngbTooltip="Configurazione progetto">
      <i class="ft-settings font-medium-1 white align-middle"></i>
    </a>
  </div>

  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <ngx-spinner>
          <p *ngIf="timing">Response time: {{ elapsedTime }} seconds</p>
        </ngx-spinner>

        <!-- Tab navigation -->
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === 'story'" id="story-tab" (click)="activeTab = 'story'" role="tab" aria-controls="story" aria-selected="true">
              <i class="ft-book"></i> Story
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab === 'testing'" id="testing-tab" (click)="activeTab = 'testing'" role="tab" aria-controls="testing" aria-selected="false">
              <i class="ft-check-square"></i> Testing
            </a>
          </li>
        </ul>

        <!-- Tab content -->
        <div class="tab-content">
          <!-- Story tab -->
          <div *ngIf="activeTab === 'story'" class="tab-pane active" id="story" role="tabpanel" aria-labelledby="story-tab">
            <div class="mt-3">
              <!-- Sticky button -->
              <div class="sticky-button-container" [class.sticky]="isSticky">
                <div class="form-group text-right">
                  <button class="btn btn-success mr-1 mb-1 mb-sm-0" (click)="goToTests()" label="Story">Area Testing
                    <span style="margin-left:10px;">
                      <i class="ft-chevron-right"></i>
                    </span>
                  </button>
                </div>
              </div>

              <!-- File upload section -->
              <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
                <div class="form-group" style="flex: 1; max-width: 30%;">
                  <label for="uploadDocFile">Upload Story</label>
                  <div class="custom-file">
                    <input type="file" *ngIf="!fileName" id="uploadDocFile" class="custom-file-input" (change)="onStorySelected()" #fileInputDocx>
                    <label for="uploadDocFile" class="custom-file-label" *ngIf="!fileName">Choose file</label>
                    <div class="input-group" *ngIf="fileName">
                      <input type="text" id="uploadDocFile" class="form-control" [(ngModel)]="fileName">
                      <div class="input-group-append">
                        <button (click)="clearFile()" class="btn btn-danger">
                          <i class="ft-trash-2"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="flex: 1; display: flex; justify-content: center;">
                  <button class="btn btn-warning" *ngIf="userStoryString || uploadCompleted" (click)="openGeneraTestsModal(generaTestModal)">
                    Genera Tests
                  </button>
                </div>

                <div style="flex: 1;"></div>
              </div>

              <!-- Additional notes -->
              <div class="col-12 mt-3">
                <app-notes></app-notes>
              </div>

              <!-- Story viewer -->
              <div *ngIf="refinedStoryString" class="col-12">
                <app-story-viewer></app-story-viewer>
              </div>

              <button class="btn btn-warning mt-4" *ngIf="userStoryString || uploadCompleted" (click)="openGeneraTestsModal(generaTestModal)">
                Genera Tests
              </button>
            </div>
          </div>

          <!-- Testing tab -->
          <div *ngIf="activeTab === 'testing'" class="tab-pane active" id="testing" role="tabpanel" aria-labelledby="testing-tab">
            <app-test></app-test>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal Genera -->

<ng-template #generaTestModal let-modal>
  <div class="gtt-modal-content">
    <div class="gtt-modal-header">
      <h3 class="gtt-modal-title">Genera Test</h3>
      <button type="button" class="gtt-close-button" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
    <div class="gtt-modal-body">
      <div class="gtt-form-group">
        <label for="modelmodal" class="gtt-label">Modello</label>
        <ng-select id="modelmodal"
                   placeholder="Seleziona modello"
                   [items]="models"
                   [(ngModel)]="selectedModel"
                   [clearable]="false"
                   (change)="updateSelectedModel()">
        </ng-select>
      </div>

      <div class="gtt-form-group">
        <label for="modelmodal" class="gtt-label">Template</label>
        <ng-select id="templatemodal"
                   placeholder="Seleziona un template"
                   [items]="templateNames"
                   [(ngModel)]="selectedTemplate"
                   [clearable]="false"
                   (change)="updateSelectedTemplate()">
        </ng-select>
      </div>

      <div class="gtt-form-group">
        <label class="gtt-label">Stile dei Test</label>
        <div class="gtt-radio-group">
          <label class="gtt-radio-label">
            <input type="radio" name="options" [(ngModel)]="testStyle" (change)="updateSelectedTestStyle()" value="Generico">
            <span class="gtt-radio-text">Generico</span>
          </label>
          <label class="gtt-radio-label">
            <input type="radio" name="options" [(ngModel)]="testStyle" (change)="updateSelectedTestStyle()" value="Atomic">
            <span class="gtt-radio-text">Post Condizione Atomica</span>
          </label>
        </div>
      </div>

      <div class="gtt-form-group">
        <label for="input" class="gtt-label">Istruzioni (Facoltativo)</label>
        <textarea id="input" class="gtt-textarea" placeholder="Qui puoi dare delle indicazioni sui test da generare" [(ngModel)]="istruzioniScriviTest" (change)="updateIstruzioniScriviTest()" rows="5"></textarea>
      </div>
    </div>
    <div class="gtt-modal-footer">
      <button type="button" class="gtt-generate-button" (click)="generaTests(); modal.dismiss('Generate click')">Genera</button>
    </div>
  </div>
</ng-template>


