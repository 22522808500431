import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloaderComponent {

  downloadGeneratedTests(
    filename: string,
    generatedTests: any[],
    columns: string[],
    selectedTemplate: string,
    templates: any[],
    context: string,
    projectId: string,
    author: string,
    selectedModel: string,
    projectLanguage: string,
    templateName: string,
    istruzioniScriviTest: string
  ) {
    const selectedTemplateColumns = templates.find(template => template.templateName === selectedTemplate)?.columns || [];

    // Check if there are any sub-tables
    const hasSubTables = generatedTests.some(test =>
      columns.some(column => this.isSubTable(test[column]))
    );

    // Create the HTML content
    let htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Generated Tests</title>
      <script type="application/json" id="metadata">
      ${JSON.stringify({ selectedTemplateColumns: selectedTemplateColumns })}
      </script>
      <script>
        function getMetaData() {
          const metadataScript = document.getElementById('metadata');
          return JSON.parse(metadataScript.textContent);
        }
      </script>
      <style>
        body {
          font-family: Arial, sans-serif;
          line-height: 1.6;
          color: #333;
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          background-color: #f4f4f4;
        }
        h1 {
          color: #2c3e50;
          text-align: center;
          margin-bottom: 30px;
        }
        table {
          border-collapse: collapse;
          width: 100%;
          margin-bottom: 20px;
          background-color: #fff;
          box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        }
        th, td {
          border: 1px solid #ddd;
          padding: 12px;
          text-align: left;
        }
        th {
          background-color: #3498db;
          color: white;
          font-weight: bold;
        }
        tr:nth-child(even) {
          background-color: #f2f2f2;
        }
        .sub-table {
          display: none;
          margin-top: 10px;
          border: 2px solid #3498db;
        }
        .sub-table th {
          background-color: #2980b9;
        }
        .toggle-btn {
          cursor: pointer;
          color: #3498db;
          text-decoration: underline;
          font-weight: bold;
        }
        .toggle-btn:hover {
          color: #2980b9;
        }
        ul {
          margin: 0;
          padding-left: 20px;
        }
        #toggleAllBtn, #showContextBtn {
          display: block;
          margin: 20px auto;
          padding: 10px 20px;
          font-size: 16px;
          color: white;
          background-color: #3498db;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        #toggleAllBtn:hover, #showContextBtn:hover {
          background-color: #2980b9;
        }
        #contextContainer {
          display: none;
          margin-top: 20px;
          padding: 20px;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
        .kpi-container {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          margin-bottom: 20px;
        }
        .kpi-item {
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 5px;
          padding: 15px;
          text-align: center;
          flex: 1 1 200px;
          margin: 10px;
        }
        .kpi-title {
          font-weight: bold;
          color: #3498db;
          margin-bottom: 5px;
        }
        .kpi-value {
          font-size: 1.2em;
        }
      </style>
      <script>
        function toggleSubTable(id) {
          var subTable = document.getElementById(id);
          if (subTable.style.display === "none") {
            subTable.style.display = "table";
          } else {
            subTable.style.display = "none";
          }
        }

        function toggleAllSubTables() {
          var subTables = document.getElementsByClassName('sub-table');
          var btnText = document.getElementById('toggleAllBtn');
          var isHidden = btnText.textContent === 'Show All Sub-Tables';

          for (var i = 0; i < subTables.length; i++) {
            subTables[i].style.display = isHidden ? 'table' : 'none';
          }

          btnText.textContent = isHidden ? 'Hide All Sub-Tables' : 'Show All Sub-Tables';
        }

        function toggleContext() {
          var contextContainer = document.getElementById('contextContainer');
          var btnText = document.getElementById('showContextBtn');
          if (contextContainer.style.display === "none") {
            contextContainer.style.display = "block";
            btnText.textContent = 'Hide Context';
          } else {
            contextContainer.style.display = "none";
            btnText.textContent = 'Show Context';
          }
        }
      </script>
    </head>
  <body>
    <h1>Generated Tests</h1>
    <div class="kpi-container">
      <div class="kpi-item">
        <div class="kpi-title">Project ID</div>
        <div class="kpi-value">${projectId}</div>
      </div>
      <div class="kpi-item">
        <div class="kpi-title">Author</div>
        <div class="kpi-value">${author}</div>
      </div>
      <div class="kpi-item">
        <div class="kpi-title">Selected Model</div>
        <div class="kpi-value">${selectedModel}</div>
      </div>
      <div class="kpi-item">
        <div class="kpi-title">Project Language</div>
        <div class="kpi-value">${projectLanguage}</div>
      </div>
      <div class="kpi-item">
        <div class="kpi-title">Template Name</div>
        <div class="kpi-value">${templateName}</div>
      </div>
      <div class="kpi-item">
        <div class="kpi-title">Total Tests</div>
        <div class="kpi-value">${generatedTests.length}</div>
      </div>
    </div>
    ${hasSubTables ? '<button id="toggleAllBtn" onclick="toggleAllSubTables()">Show All Sub-Tables</button>' : ''}
    <button id="showContextBtn" onclick="toggleContext()">Show Context</button>
    <div id="contextContainer">
      <h3>Context:</h3>
      <p>${context}</p>
        ${istruzioniScriviTest ? `
      <h3>Istruzioni:</h3>
      <p>${istruzioniScriviTest}</p>
    ` : ''}
  </div>
    <table>
      <thead>
        <tr>
          ${columns.map(column => `<th>${column}</th>`).join('')}
        </tr>
      </thead>
      <tbody>
  `;

    // Add each test to the HTML content
    generatedTests.forEach((test, testIndex) => {
      htmlContent += '<tr>';
      columns.forEach(column => {
        htmlContent += '<td>';
        if (this.isSubTable(test[column])) {
          // Handle sub-table
          const subTableId = `subTable_${testIndex}_${column}`;
          htmlContent += `
          <span class="toggle-btn" onclick="toggleSubTable('${subTableId}')">Toggle ${column}</span>
          ${this.generateSubTableHtml(test[column], subTableId)}
        `;
        } else if (Array.isArray(test[column])) {
          // Handle array
          htmlContent += this.generateListHtml(test[column]);
        } else {
          // Handle simple value
          htmlContent += this.formatContent(test[column]);
        }
        htmlContent += '</td>';
      });
      htmlContent += '</tr>';
    });

    // Close the HTML structure
    htmlContent += `
      </tbody>
    </table>
  </body>
  </html>
  `;

    // Create a Blob with the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html;charset=utf-8' });

    // Use the downloadBlob function to download the file
    this.downloadBlob(blob, filename + '.html');
  }

  private generateListHtml(items: any[]): string {
    if (items.length === 0) { return ''; }
    return `
      <ul class="list-content">
        ${items.map(item => `<li>${this.formatContent(item)}</li>`).join('')}
      </ul>
    `;
  }

  isSubTable(value: any): boolean {
    return value && typeof value === 'object' && 'headers' in value && 'rows' in value;
  }

  private generateSubTableHtml(subTableData: any, subTableId: string): string {
    if (!subTableData || typeof subTableData !== 'object') {
      return '<p>No data available</p>';
    }

    let headers: string[] = [];
    let rows: any[] = [];

    // Try to identify headers and rows
    if (Array.isArray(subTableData)) {
      // If it's an array, assume the first item contains headers
      headers = Object.keys(subTableData[0] || {});
      rows = subTableData;
    } else if (subTableData.headers && subTableData.rows) {
      // If it has headers and rows properties
      headers = subTableData.headers;
      rows = subTableData.rows;
    } else {
      // If it's an object, use its keys as headers and values as a single row
      headers = Object.keys(subTableData);
      rows = [this.objectToArray(subTableData)];
    }

    if (headers.length === 0 || rows.length === 0) {
      return '<p>No data available</p>';
    }

    let subTableHtml = `
    <table id="${subTableId}" class="sub-table">
      <thead>
        <tr>
          ${headers.map(header => `<th>${header}</th>`).join('')}
        </tr>
      </thead>
      <tbody>
  `;

    rows.forEach(row => {
      subTableHtml += '<tr>';
      if (Array.isArray(row)) {
        row.forEach(cell => {
          subTableHtml += `<td>${cell !== undefined && cell !== null ? cell : ''}</td>`;
        });
      } else if (typeof row === 'object') {
        headers.forEach(header => {
          subTableHtml += `<td>${row[header] !== undefined && row[header] !== null ? row[header] : ''}</td>`;
        });
      } else {
        subTableHtml += `<td>${row !== undefined && row !== null ? row : ''}</td>`;
      }
      subTableHtml += '</tr>';
    });

    subTableHtml += `
      </tbody>
    </table>
  `;

    return subTableHtml;
  }

  private formatContent(content: any): string {
    if (content === undefined || content === null) { return ''; }
    if (typeof content === 'string') {
      // Replace newlines with <br> tags for proper HTML rendering
      return content.replace(/\n/g, '<br>');
    }
    return String(content);
  }

  private objectToArray(obj: {[key: string]: any}): any[] {
    return Object.keys(obj).map(key => obj[key]);
  }

  private downloadBlob(blob: Blob, filename: string) {
    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Append the link to the body (this is required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }


  downloadCSVGeneratedTests(filename: string, generatedTests: any[], columns: string[]) {
    // Function to replace pipe characters with a substitute character
    const replacePipes = (value: any): any => {
      if (typeof value === 'string') {
        return value.replace(/\|/g, '-');
      }
      return value;
    };

    // Create CSV content with pipe as separator
    let csvContent = '\ufeff'; // Add BOM for Excel to recognize UTF-8
    csvContent += columns.join('|') + '\n';

    generatedTests.forEach(test => {
      const row = columns.map(column => {
        let cellValue = replacePipes(test[column]);
        if (this.isSubTable(cellValue)) {
          return 'Sub-table data';
        } else if (Array.isArray(cellValue)) {
          return cellValue.map(replacePipes).join(';');
        } else {
          return cellValue !== undefined && cellValue !== null ? cellValue : '';
        }
      }).join('|');
      csvContent += row + '\n';
    });

    // Create a Blob with the CSV content, explicitly setting UTF-8 encoding
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Use the downloadBlob function to download the file
    this.downloadBlobCSV(blob, filename + '.csv');
  }

// Update the downloadBlob function to handle UTF-8 encoding
  private downloadBlobCSV(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  downloadStoryFile(refinedStoryString, fileName) {
    const blob = new Blob([refinedStoryString], { type: 'text/html' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.html`;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
