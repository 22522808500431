<footer class="footer">
  <p class="copyright clearfix text-muted m-0">
    Copyright &copy; {{currentDate | date: 'yyyy'}} <a id="pixinventLink"
                                                       href="https://nexsoft.it/">NEXSOFT</a>
    <span class="d-none d-sm-inline-block">, All rights reserved.</span>
    <span class="right-text">{{versione}}</span>
  </p>
</footer>

<style>
  .right-text {
    float: right;
  }
</style>
