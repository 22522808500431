<ngx-spinner>
  <p *ngIf="timing">Response time: {{ elapsedTime }} seconds</p>
</ngx-spinner>

<div class="notes-container">
  <div class="notes-toggle">
    <div class="d-flex">
      <h5 class="mr-auto">Additional Notes</h5>

      <button *ngIf="isNotesCollapsed"
              type="button"
              class="btn btn-outline-dark"
              (click)="isNotesCollapsed = !isNotesCollapsed"
              aria-controls="addnotes"
              [attr.aria-expanded]="!isNotesCollapsed">
        {{ isNotesCollapsed ? 'Mostra' : 'Chiudi' }}
      </button>

      <button *ngIf="!isNotesCollapsed"
              type="button"
              class="btn btn-dark"
              (click)="isNotesCollapsed = !isNotesCollapsed"
              aria-controls="addnotes"
              [attr.aria-expanded]="!isNotesCollapsed">
        {{ isNotesCollapsed ? 'Mostra' : 'Chiudi' }}
      </button>

    </div>
  </div>

  <div class="notes-content" [ngClass]="{'show': !isNotesCollapsed}" id="addnotes">
    <div class="card">
      <div class="card-header d-flex">

        <div class='col-12 col-sm-4 mr-auto p-2'>
          <div class="form-group">
            <label for="uploadAddNotesFile">Upload Additional Context</label>
            <div class="custom-file">
              <input type="file" *ngIf="!additionalNotesFileName" id="uploadAddNotesFile" class="custom-file-input" (change)="onAdditionalNotesFileSelected()" #fileAddNotes>
              <label for="uploadAddNotesFile" class="custom-file-label" *ngIf="!additionalNotesFileName"></label>
              <div class="input-group" *ngIf="additionalNotesFileName">
                <input type="text" id="uploadAddNotesFile" class="form-control" [(ngModel)]="additionalNotesFileName">
                <div class="input-group-append">
                  <button (click)="clearAdditionalNotesFile()" class="btn btn-danger">
                    <i class="ft-trash-2"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="d-flex align-items-center">
            <button class="btn btn-success mr-2" (click)="openSelezionaNotesModal(displayNotesModal)">Seleziona Notes</button>
            <button *ngIf="additionalNotes && isQAnalystOrManager" class="btn btn-success" (click)="salvaNotes()">Salva Notes</button>
          </div>
        </div>

      <div class="card-body">
        <textarea class="form-control notes-textarea" [(ngModel)]="additionalNotes" (ngModelChange)="onNotesChange($event)"></textarea>
      </div>
    </div>
  </div>
</div>





<!-- MODAL MOSTRA NOTES -->

  <ng-template #displayNotesModal let-modal>
    <div class="notes-modal-content">
      <ngx-spinner>
        <p *ngIf="timing">Response time: {{ elapsedTime }} seconds</p>
      </ngx-spinner>
      <div class="notes-modal-header">

        <h3 class="notes-modal-title">Retrieved Notes</h3>
        <button type="button" class="notes-close-button" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      <div class="notes-modal-body">
        <ng-container *ngIf="retrievedNotes && retrievedNotes.length > 0; else noNotes">
          <div *ngFor="let note of retrievedNotes; let i = index" class="note-item">
            <!-- Existing note display logic -->
            <div class="note-header">
              <div class="note-checkbox-group">
                <span *ngIf="note.selectionOrder == null" class="note-selection-order mr-1"></span>
                <span *ngIf="note.selectionOrder !== null" class="note-selection-order mr-1">{{note.selectionOrder}}</span>
                <input type="checkbox"
                       [checked]="note.selectionOrder !== null"
                       (change)="toggleNoteSelection(note)"
                       [id]="'note-' + i">
                <label [for]="'note-' + i" class="note-checkbox-label">
                  <span class="note-property"><strong>ID:</strong> {{note.id}}</span>
                  <span class="note-property"><strong>Date:</strong> {{note.date}}</span>
                  <span *ngIf="note.similarity" class="note-property"><strong>Similarity:</strong> {{(note.similarity * 100) | number: '1.2-2'}}%</span>
                </label>
              </div>

              <div *ngIf="!note.isEditing" class="note-actions">
                <button class="btn btn-success mr-1" (click)="editNote(note)">Edit</button>
                <button class="btn btn-danger" (click)="deleteNote(note.id)">Delete</button>
                <button class="note-toggle-button" (click)="note.expanded = !note.expanded">
                  {{ note.expanded ? '▲' : '▼' }}
                </button>
              </div>

              <div *ngIf="note.isEditing" class="note-actions">
                <button class="btn btn-success mr-1" (click)="saveNoteChanges(note)">Save</button>
                <button class="btn btn-light mr-1" (click)="cancelNoteEdit(note)">Cancel</button>
                <button class="btn btn-danger" (click)="deleteNote(note.id)">Delete</button>
                <button class="note-toggle-button" (click)="note.expanded = !note.expanded">
                  {{ note.expanded ? '▲' : '▼' }}
                </button>
              </div>

            </div>
            <div class="note-content" [class.expanded]="note.expanded">
              <ng-container *ngIf="!note.isEditing; else editMode">
                {{note.content}}
              </ng-container>
              <ng-template #editMode>
                <textarea [(ngModel)]="note.editedContent" class="form-control" rows="15"></textarea>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <ng-template #noNotes>
          <div class="no-notes-message">
            <p>No notes found in this project.</p>
          </div>
        </ng-template>
      </div>
      <div class="notes-modal-footer">
        <button type="button" [hidden]="true" class="btn btn-warning mr-2" (click)="retrieveSimilarNotes()" [disabled]="!retrievedNotes || retrievedNotes.length === 0">Cerca per similarità</button>

        <button type="button" class="btn btn-success" (click)="selectNotes()" [disabled]="!retrievedNotes || retrievedNotes.length === 0">Select Notes</button>
      </div>
    </div>
  </ng-template>
