import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StoryComponent } from './story/story.component';
import { TestComponent} from './test/test.component';
import { ConfigurationComponent } from './configuration/configuration.component';

const routes: Routes = [
  {path: 'story/:project_id', component: StoryComponent},
  {

    path: 'story',
    component: StoryComponent,
    data: {
      title: 'Scriptor'
    }
  },
  {path: 'test/:project_id', component: TestComponent},
  {
    path: 'test',
    component: TestComponent,
    data: {
      title: 'Scriptor'
    }
  },
  {
    path: 'configuration',
    component: ConfigurationComponent,
    data: {
      title: 'Configuration'
    }
  },
  {path: 'configuration/:project_id', component: ConfigurationComponent}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class ProjectsRoutingModule { }
