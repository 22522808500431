
<div class="test-info-card coverage-card" *ngIf="generatedTests.length > 0"
     (click)="openCoverageModal(coverageModal)"
     style="cursor: pointer;">
  <div class="card-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
      <path d="M14 2v6h6"></path>
      <circle cx="11" cy="14" r="2"></circle>
      <path d="M13.5 16.5L16 19"></path>
    </svg>
  </div>
  <div class="card-content">
    <h3 class="card-value">Suggestions</h3>
    <p class="card-label">Out of the Box</p>
  </div>
</div>



<!-- Modal Genera -->

<ng-template #coverageModal let-modal>
  <ngx-spinner>
    <p *ngIf="timing">Response time: {{ elapsedTime }} seconds</p>
  </ngx-spinner>

  <div class="modal-header">
    <h5 class="modal-title" id="testModalLabel">New Test Cases Suggestions</h5>
    <button type="button" class="gtt-close-button" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </button>
  </div>

  <div class="gtt-modal-body">

    <div class="gtt-form-group">
      <label for="modelmodal" class="gtt-label">Modello</label>
      <ng-select id="modelmodal"
                 placeholder="Seleziona modello"
                 [items]="models"
                 [(ngModel)]="selectedModel"
                 [clearable]="false"
                 (change)="updateSelectedModel()">
      </ng-select>
    </div>

    <div class="card">
      <div class="scrollable-text">{{analisi}}</div>

    </div>

  </div>

  <div class="gtt-modal-footer">
    <button class="btn btn-warning mr-2" (click)="getAnalysis()">Analizza</button>
  </div>

</ng-template>
