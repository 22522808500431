import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../shared/services/api.service';
import { StatoSessioneService } from '../../../../shared/services/stato-sessione.service';
import { SpinnerService } from '../../../../shared/services/spinner.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface Column {
  id: number;
  name: string;
  description: string;
  list: boolean;
  subColumns?: Column[];
  expanded?: boolean;
}

interface PreviewColumn {
  name: string;
  value?: string;
  subColumns?: PreviewColumn[];
  expanded: boolean;
}

interface Template {
  value: string;
  name: string;
}

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {
  private _selectedTemplateName: string;

  defaultTemplate: string | null = null;
  templateDescription = '';
  projectId: string;
  columnsForm: FormGroup;
  selectedTemplate: string;
  templateName = 'Default Template';
  previewColumns: PreviewColumn[] = [];
  templates: any[] = [];
  templateNames: string[] = [];

  constructor(
    private sessioneService: StatoSessioneService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private spinnerService: SpinnerService
  ) {}

  @Input() set selectedTemplateName(value: string) {
    this._selectedTemplateName = value;
    if (this.templateNames && this.templateNames.includes(value)) {
      this.selectedTemplate = value;
      this.handleTemplateChange();
      this.scrollToTop();
    }
  }

  get selectedTemplateName(): string {
    return this._selectedTemplateName;
  }

  ngOnInit(): void {
    this.initForm();
    this.projectId = this.route.snapshot.params.project_id;
    this.fetchTemplateNames(this.projectId);
  }

  initForm(): void {
    this.columnsForm = this.fb.group({
      columns: this.fb.array([])
    });
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  fetchTemplateNames(projectId: string) {
    this.spinnerService.show();
    this.apiService.getTemplates(projectId).subscribe(
      data => {
        this.sessioneService.updateTemplates(data);
        this.templates = data;
        this.templateNames = this.templates.map(template => template.templateName);
        // Set the default template as the first one in the list
        this.defaultTemplate = this.templateNames.length > 0 ? this.templateNames[0] : null;
        this.selectedTemplate = this.defaultTemplate;
        this.initializeSelectedTemplate();
        this.spinnerService.hide();
        console.log('Fetched templates:', this.templates);
        console.log('Default template:', this.defaultTemplate);
        console.log('Selected template:', this.selectedTemplate);
      },
      error => {
        console.error('Error fetching template names', error);
        this.spinnerService.hide();
      }
    );
  }

  isDefaultTemplate(templateName: string): boolean {
    return this.templateNames.indexOf(templateName) === 0;
  }

  initializeSelectedTemplate(): void {
    if (this._selectedTemplateName && this.templateNames.includes(this._selectedTemplateName)) {
      this.selectedTemplate = this._selectedTemplateName;
    } else if (this.templateNames.length > 0) {
      this.selectedTemplate = this.templateNames[0];
    }

    if (this.selectedTemplate) {
      this.sessioneService.updateSelectedTemplate(this.selectedTemplate);
      this.handleTemplateChange();
    }
  }

  handleTemplateChange(): void {
    if (this.selectedTemplate && this.projectId) {
      this.apiService.getTemplateDetails(this.projectId, this.selectedTemplate).subscribe({
        next: (response) => {
          console.log(response);
          this.templateName = response.templateName;
          this.templateDescription = response.templateDescription;
          this.templates[this.selectedTemplate] = response.columns;
          this.loadTemplate();
        },
        error: (error) => {
          console.error('Error fetching template details:', error);
        }
      });
    }
  }

  loadTemplate(): void {
    if (this.selectedTemplate) {
      const columnsArray = this.columnsForm.get('columns') as FormArray;
      columnsArray.clear();

      if (this.templates[this.selectedTemplate]) {
        this.templates[this.selectedTemplate].forEach(column => {
          columnsArray.push(this.createColumnGroup(column));
        });
      }

      this.updatePreview();
    }
  }

  createColumnGroup(column: Column): FormGroup {
    const group = this.fb.group({
      id: [column.id],
      name: [column.name],
      description: [column.description],
      list: [column.list],
      expanded: [column.expanded || false],
      subColumns: this.fb.array([])
    });

    if (column.subColumns) {
      column.subColumns.forEach(subColumn => {
        (group.get('subColumns') as FormArray).push(this.createColumnGroup(subColumn));
      });
    }

    return group;
  }

  addColumn(index?: number): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    const newId = Math.max(...columnsArray.value.map((c: Column) => c.id), 0) + 1;
    const newColumn = this.createColumnGroup({ id: newId, name: '', description: '', list: false });

    if (index !== undefined) {
      columnsArray.insert(index, newColumn);
    } else {
      columnsArray.push(newColumn);
    }

    this.updatePreview();
  }

  removeColumn(index: number): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    columnsArray.removeAt(index);
    this.updatePreview();
  }

  addSubColumn(columnIndex: number): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    const subColumnsArray = columnsArray.at(columnIndex).get('subColumns') as FormArray;
    const newId = Math.max(...subColumnsArray.value.map((sf: Column) => sf.id), columnsArray.at(columnIndex).value.id * 100) + 1;
    subColumnsArray.push(this.createColumnGroup({ id: newId, name: '', description: '', list: false }));
    columnsArray.at(columnIndex).patchValue({ expanded: true });
    this.updatePreview();
  }

  removeSubColumn(columnIndex: number, subColumnIndex: number): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    const subColumnsArray = columnsArray.at(columnIndex).get('subColumns') as FormArray;
    subColumnsArray.removeAt(subColumnIndex);
    this.updatePreview();
  }

  toggleExpand(columnForm: FormGroup): void {
    columnForm.patchValue({ expanded: !columnForm.get('expanded').value });
  }

  moveColumn(event: CdkDragDrop<string[]>): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    moveItemInArray(columnsArray.controls, event.previousIndex, event.currentIndex);

    // Update the form values to trigger change detection
    const updatedColumns = columnsArray.controls.map(control => control.value);
    columnsArray.patchValue(updatedColumns);

    // Force change detection
    columnsArray.updateValueAndValidity();

    // Update the preview
    this.updatePreview();
  }

  updatePreview(): void {
    const columnsArray = this.columnsForm.get('columns') as FormArray;
    this.previewColumns = columnsArray.controls.map(control => {
      const column = control.value;
      const previewColumn: PreviewColumn = {
        name: column.name,
        expanded: false
      };

      if (column.subColumns && column.subColumns.length > 0) {
        previewColumn.subColumns = column.subColumns.map(subColumn => ({
          name: subColumn.name,
          value: 'Test',
          expanded: false
        }));
      } else {
        previewColumn.value = 'Test';
      }

      return previewColumn;
    });
  }

  togglePreviewExpand(column: PreviewColumn): void {
    column.expanded = !column.expanded;
  }

  previewConfiguration(): void {
    this.updatePreview();
  }

  saveConfiguration(): void {
    this.spinnerService.show();
    this.apiService.saveConfiguration(
      this.projectId,
      this.templateName,
      this.templateDescription,
      this.columnsForm.value.columns
    ).subscribe(
      response => {
        console.log('Configuration saved successfully:', response);
        this.spinnerService.hide();
        alert('Configuration saved successfully!');
      },
      error => {
        console.error('Error saving configuration:', error);
        this.spinnerService.hide();
        alert('Error saving configuration:');
      }
    );
  }

  deleteSelectedTemplate() {
    if (this.selectedTemplate && this.projectId) {
      if (confirm(`Are you sure you want to delete the template "${this.selectedTemplate}"?`)) {
        this.spinnerService.show();
        this.apiService.deleteConfig(this.projectId, this.selectedTemplate).subscribe({
          next: (response) => {
            this.templateNames = this.templateNames.filter(t => t !== this.selectedTemplate);
            this.selectedTemplate = null;
            this.columnsForm.reset();
            this.templateName = '';
            this.templateDescription = '';
            this.fetchTemplateNames(this.projectId);
            this.spinnerService.hide();
            alert('Template deleted successfully');
          },
          error: (error) => {
            console.error('Error deleting template', error);
            this.spinnerService.hide();
            alert('Error deleting template. Please try again.');
          }
        });
      }
    }
  }

  makeDefaultTemplate() {
    if (this.selectedTemplate && this.projectId) {
      this.spinnerService.show();
      this.apiService.setDefaultTemplate(this.projectId, this.selectedTemplate).subscribe({
        next: () => {
          this.templateNames = [this.selectedTemplate, ...this.templateNames.filter(name => name !== this.selectedTemplate)];
          this.defaultTemplate = this.selectedTemplate;
          this.templates = [
            this.templates.find(t => t.templateName === this.selectedTemplate),
            ...this.templates.filter(t => t.templateName !== this.selectedTemplate)
          ];
          this.spinnerService.hide();
          alert('Template set as default successfully');
        },
        error: (error) => {
          console.error('Error setting default template', error);
          this.spinnerService.hide();
          alert('Error setting default template. Please try again.');
        }
      });
    }
  }
}
