// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


// FIRE BASE DB
// PROD

export const environment = {
    production: false,
    firebase: {
      apiKey: 'AIzaSyAQBV6GO-D5Df4mt3gSfRzb6JcZYaFrP2Q',
      authDomain: 'scriptor-90ee9.firebaseapp.com',
      projectId: 'scriptor-90ee9',
      storageBucket: 'scriptor-90ee9.appspot.com',
      messagingSenderId: '865618877576',
      appId: '1:865618877576:web:a7afce13a8fb502a54533d'
    }
}



/* DEV
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBi-STDgf6Ku03owvgFOR2M4kjeM3KKOzo',
    authDomain: 'scriptordev-522c7.firebaseapp.com',
    projectId: 'scriptordev-522c7',
    storageBucket: 'scriptordev-522c7.appspot.com',
    messagingSenderId: '576564804193',
    appId: '1:576564804193:web:db144b132155e8d3f84820',
    measurementId: 'G-P018EPB5N7'
  }
}
*/
// BACKEND URL ENDPOINT
//
// export const apiUrl = 'https://scriptordevbe.azurewebsites.net'
//
// export const apiUrl = 'https://scriptorapi99.azurewebsites.net';
// export const apiUrl = 'https://scriptorbackend2.azurewebsites.net'
// export const apiUrl = 'https://scriptordevbackend.azurewebsites.net'
// export const apiUrl = 'https://scriptortestbackend.azurewebsites.net'
// export const apiUrl = 'http://127.0.0.1:8000';
// export const apiUrl = 'http://87.106.117.68:8000';
export const apiUrl = 'https://scriptor.nexsoft.it:8443';

// VERSIONE
export const versione = 'Ver 1.0.0'
