import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import firebase from 'firebase/app';

export interface Project {
  id?: string;
  name: string;
  description: string;
  assignedUsers: string[];
}

export interface ProjectDetails {
  language: 'it' | 'en';
  projectDescription: string;
  projectManager: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProgettiService {
  constructor(private firestore: AngularFirestore) {}

  getAllProjects(): Observable<Project[]> {
    return this.firestore.collection<Project>('projects').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Project;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getProjectsByUser(userId: string): Observable<Project[]> {
    // console.log('Fetching projects for user:', userId);
    return this.firestore.collection<Project>('projects', ref =>
      ref.where('assignedUsers', 'array-contains', userId)
    ).snapshotChanges().pipe(
      map(actions => {
        // console.log('Firestore actions:', actions);
        return actions.map(a => {
          const data = a.payload.doc.data() as Project;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }),
      map(projects => {
        // console.log('Mapped projects:', projects);
        return projects;
      })
    );
  }

  createProject(project: Project): Promise<void> {
    return this.firestore.collection('projects').doc(project.name).set(project);
  }

  updateProject(projectId: string, project: Partial<Project>): Promise<void> {
    return this.firestore.doc(`projects/${projectId}`).update(project);
  }

  assignUserToProject(projectId: string, userId: string): Promise<void> {
    return this.firestore.doc(`projects/${projectId}`).update({
      assignedUsers: firebase.firestore.FieldValue.arrayUnion(userId)
    });
  }

  removeUserFromProject(projectId: string, userId: string): Promise<void> {
    return this.firestore.doc(`projects/${projectId}`).update({
      assignedUsers: firebase.firestore.FieldValue.arrayRemove(userId)
    });
  }

  removeProject(projectId: string): Observable<void> {
    return from(this.firestore.doc(`projects/${projectId}`).get()).pipe(
      switchMap(projectDoc => {
        if (!projectDoc.exists) {
          throw new Error('Project not found');
        }
        const project = projectDoc.data() as Project;
        const batch = this.firestore.firestore.batch();

        // Remove the project document
        batch.delete(this.firestore.doc(`projects/${projectId}`).ref);

        // Remove the project from all assigned users
        const userUpdates = project.assignedUsers.map(userId =>
          this.firestore.doc(`users/${userId}`).update({
            assignedProjects: firebase.firestore.FieldValue.arrayRemove(projectId)
          })
        );

        return from(Promise.all([batch.commit(), ...userUpdates]));
      }),
      map(() => undefined)
    );
  }

  getProjectDetails(projectId: string): Observable<ProjectDetails | undefined> {
    return this.firestore.doc<ProjectDetails>(`projectDetails/${projectId}`).valueChanges();
  }

  updateProjectDetails(projectId: string, details: ProjectDetails): Promise<void> {
    return this.firestore.doc(`projectDetails/${projectId}`).set(details, { merge: true });
  }

  getProjectLanguage(projectId: string): Observable<string | undefined> {
    return this.firestore.doc<ProjectDetails>(`projectDetails/${projectId}`)
      .valueChanges()
      .pipe(
        map(details => details?.language)
      );
  }
}
