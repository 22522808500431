<div class="notes-container">
  <div class="notes-toggle">
    <div class="d-flex">
      <h5 class="mr-auto">User Story</h5>

      <button *ngIf="isCollapsed"
              type="button"
              class="btn btn-outline-dark"
              (click)="isCollapsed = !isCollapsed"
              aria-controls="addnotes"
              [attr.aria-expanded]="!isCollapsed">
        {{ isCollapsed ? 'Mostra' : 'Chiudi' }}
      </button>

      <button *ngIf="!isCollapsed"
              type="button"
              class="btn btn-dark"
              (click)="isCollapsed = !isCollapsed"
              aria-controls="addnotes"
              [attr.aria-expanded]="!isCollapsed">
        {{ isCollapsed ? 'Mostra' : 'Chiudi' }}
      </button>

    </div>
  </div>

  <div class="notes-content" [ngClass]="{'show': !isCollapsed}" id="addnotes">
    <div class="card">
      <div class="card-header d-flex">
        <div class="d-flex align-items-center">
          <button class="btn btn-outline-primary ml-1" (click)="downloadHtmlFile()"><i class="ft-download mr-1"></i>Download Story</button>
          <button ngxClipboard [cbContent]="getEditorContent()" class="btn btn-outline-primary ml-1">Copy Story</button>
        </div>
      </div>

      <div class="card-body">
        <div #editor></div>
      </div>
    </div>
  </div>
</div>
