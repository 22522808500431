import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StatoSessioneService } from 'app/shared/services/stato-sessione.service';
import { ApiService } from 'app/shared/services/api.service';
import { ProgettiService, ProjectDetails } from '../../../shared/services/progetti.service';
import {SpinnerService} from '../../../shared/services/spinner.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  projectId: string;
  fileName: string;
  uploadCompleted = false;
  activeTab: 'general' | 'columns' | 'manage' = 'general';
  projectForm: FormGroup;
  selectedTemplateName: string;

  constructor(
    private route: ActivatedRoute,
    private sessioneService: StatoSessioneService,
    private apiService: ApiService,
    private progettiService: ProgettiService,
    private fb: FormBuilder,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
    this.projectId = this.route.snapshot.params.project_id;
    this.initForm();
    this.loadProjectDetails();
  }

  onTabChange(event: {tab: 'general' | 'columns' | 'manage', templateName?: string}): void {
    this.activeTab = event.tab;
    if (event.templateName) {
      this.selectedTemplateName = event.templateName;
    }
  }

  initForm() {
    this.projectForm = this.fb.group({
      language: ['', Validators.required],
      projectDescription: ['', Validators.required],
      projectManager: ['', Validators.required]
    });
  }

  loadProjectDetails() {
    this.progettiService.getProjectDetails(this.projectId).subscribe(
      (details: ProjectDetails) => {
        if (details) {
          this.projectForm.patchValue({
            language: details.language,
            projectDescription: details.projectDescription,
            projectManager: details.projectManager
          });
        }
      },
      error => console.error('Error loading project details:', error)
    );
  }

  clearFile(): void {
    this.fileName = '';
    this.sessioneService.updateFileName('');
  }

  onSave() {
    if (this.projectForm.valid) {
      this.spinnerService.show();
      const updatedDetails: ProjectDetails = this.projectForm.value;

      this.progettiService.updateProjectDetails(this.projectId, updatedDetails)
        .then(() => {
          this.spinnerService.hide()
          alert('Configuration saved successfully!');
          console.log('Project details updated successfully');
          // You might want to show a success message to the user here
        })
        .catch(error => {
          this.spinnerService.hide()
          alert('Error updating project details');
          console.error('Error updating project details:', error);
          // You might want to show an error message to the user here
        });
    } else {
      alert('Form is invalid');
      console.log('Form is invalid');
      // You might want to show validation errors to the user here
    }
  }
}
