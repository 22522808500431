import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService} from '../shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/pages/login']);
      return false;
    }

    return this.authService.hasRole('Admin').pipe(
      take(1),
      map((isAdmin: boolean) => {
        if (!isAdmin) {
          this.router.navigate(['/dashboard']); // Redirect non-admin users to dashboard
          return false;
        }
        return true;
      })
    );
  }
}
