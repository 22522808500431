import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private startTime: number;
  private intervalId: any;
  private elapsedTimeSubject = new BehaviorSubject<string>('00:00');
  public elapsedTime$ = this.elapsedTimeSubject.asObservable();
  public isShowing = false;

  constructor(private spinner: NgxSpinnerService) {}

  show() {
    this.spinner.show(undefined, {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: true
    });
    this.startTime = Date.now();
    this.intervalId = setInterval(() => {
      this.updateElapsedTime();
    }, 1000);
  }

  hide() {
    this.isShowing = false;
    this.spinner.hide();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  private updateElapsedTime() {
    const elapsedSeconds = Math.floor((Date.now() - this.startTime) / 1000);
    const minutes = Math.floor(elapsedSeconds / 60);
    const seconds = elapsedSeconds % 60;
    this.elapsedTimeSubject.next(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
  }
}
