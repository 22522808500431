import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';
import { AuthService, UserData, UserRole } from '../auth/auth.service';
import { ProgettiService, Project } from './progetti.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  private routesSubject: BehaviorSubject<RouteInfo[]>;
  public routes$: Observable<RouteInfo[]>;

  private readonly baseRoutes: RouteInfo[] = [
    {
      path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    }
  ];

  constructor(
    private authService: AuthService,
    private projectService: ProgettiService
  ) {
    this.routesSubject = new BehaviorSubject<RouteInfo[]>([]);
    this.routes$ = this.routesSubject.asObservable();

    this.initializeRoutes();
  }
// quando cambio utente refresha le routes ai progetti
  private initializeRoutes() {
    this.authService.getCurrentUserData().pipe(
      switchMap((userData: UserData | null) => {
        if (!userData) {
          return of([this.baseRoutes, false, []]);
        }
        return combineLatest([
          of(this.baseRoutes),
          this.authService.hasRole('Admin'),
          this.projectService.getProjectsByUser(userData.uid)
        ]);
      })
    ).subscribe(([initialRoutes, isAdmin, userProjects]: [RouteInfo[], boolean, Project[]]) => {
      const projectsRoute: RouteInfo = {
        path: '', title: 'Progetti', icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-info float-right mr-1 mt-1', isExternalLink: false,
        submenu: userProjects.map(project => ({
          path: `/projects/story/${project.id}`,
          title: project.name,
          icon: 'ft-layout',
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        }))
      };

      const updatedRoutes = [...initialRoutes, projectsRoute];

      if (isAdmin) {
        const adminRoute: RouteInfo = {
          path: '/admin', title: 'Admin Panel', icon: 'ft-shield', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        };
        updatedRoutes.push(adminRoute);
      }

      this.routesSubject.next(updatedRoutes);
    });
  }
}
