import {Component, OnDestroy, OnInit, ViewChild, ElementRef, HostListener, SecurityContext, ChangeDetectorRef} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Subject, Subscription} from 'rxjs';
import { ApiService } from 'app/shared/services/api.service';
import { StatoSessioneService } from 'app/shared/services/stato-sessione.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../shared/auth/auth.service';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {TestComponent} from '../test/test.component';
import {ProgettiService} from '../../../shared/services/progetti.service';
import {Spinner} from 'ngx-spinner/lib/ngx-spinner.enum';
import {SpinnerService} from '../../../shared/services/spinner.service';

@Component({
  selector: 'tool-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit, OnDestroy {
  @ViewChild('fileInputDocx') fileInputDocx: ElementRef<HTMLInputElement>;
  @ViewChild(TestComponent) testComponent: TestComponent; // Add this line

  // Existing variables
  ruolo: string;
  istruzioniScriviTest: string;
  models: string[];
  templates: any[];
  templateNames: any[];
  testStyle: string;
  selectedModel: string;
  selectedTemplate: string;
  refinedStoryString: string;
  userStoryString: string;
  chunks: string[];
  fileName: string;
  projectLanguage: string;
  uploadCompleted = false;
  additionalNotes: string;
  projectId: string;
  activePanelIds: string[] = [];
  timing = false;
  elapsedTime = 0;
  private destroy$: Subject<void> = new Subject<void>();
  isSticky = false;
  stickyThreshold = 150;
  isQAnalystOrManager = false;

  // New variable for active tab
  activeTab: 'story' | 'testing' = 'story';

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.isSticky = window.scrollY > this.stickyThreshold;
  }

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private sessioneService: StatoSessioneService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private progettiService: ProgettiService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.initializeProjectData();
    this.initializeComponentData();
    this.subscribeToSessionService();

    this.isQAnalystOrManager = this.ruolo === 'Admin' || this.ruolo === 'Manager' || this.ruolo === 'QA Analyst';
  }

  private initializeProjectData(): void {
    this.route.params.pipe(
      tap(params => {
        this.projectId = params['project_id'];
        console.log('Project ID changed:', this.projectId);
        this.sessioneService.updateProjectId(this.projectId);
        // this.sessioneService.resetAll();
        this.fetchTemplateNames(this.projectId);
      }),
      switchMap(params => this.progettiService.getProjectLanguage(params['project_id'])),
      takeUntil(this.destroy$)
    ).subscribe({
      next: (language) => {
        if (language) {
          this.projectLanguage = language;
          this.sessioneService.updateProjectLanguage(language);
          console.log('Project language:', language);
        }
      },
      error: (error) => {
        console.error('Error fetching project language:', error);
        // Handle error (e.g., show a notification to the user)
      }
    });
  }


  private initializeComponentData(): void {
    this.models = ['Haiku', 'Sonnet 3.5', 'Omni mini', 'Omni', 'LLama3.1 70b', 'LLama3 70b', 'LLama3.1 8b', 'Mixtral', 'Gemini Flash', 'Gemini Pro'];
    this.sessioneService.updateSelectedModel('Omni');
    this.sessioneService.updateSelectedTestStyle('Generico');
  }

  private subscribeToSessionService(): void {
    this.sessioneService.fileName$.pipe(takeUntil(this.destroy$)).subscribe(filename => this.fileName = filename);
    this.sessioneService.additionalContext$.pipe(takeUntil(this.destroy$)).subscribe(add_notes => this.additionalNotes = add_notes);
    this.sessioneService.bodySubject$.pipe(takeUntil(this.destroy$)).subscribe(body => this.userStoryString = body);
    this.sessioneService.selectedModel$.pipe(takeUntil(this.destroy$)).subscribe(model => this.selectedModel = model);
    this.sessioneService.selectedTemplate$.pipe(takeUntil(this.destroy$)).subscribe(template => this.selectedTemplate = template);
    this.sessioneService.templates$.pipe(takeUntil(this.destroy$)).subscribe(templates => {
      this.templates = templates;
      this.templateNames = this.templates.map(template => template.templateName);
      this.sessioneService.updateSelectedTemplate(this.templateNames[0]);
    });
    this.sessioneService.selectedTestStyle$.pipe(takeUntil(this.destroy$)).subscribe(style => this.testStyle = style);
    this.sessioneService.istruzioniScriviTest$.pipe(takeUntil(this.destroy$)).subscribe(instruct => this.istruzioniScriviTest = instruct);
    this.sessioneService.refinedStoryString$.pipe(takeUntil(this.destroy$)).subscribe(refinedStoryString => this.refinedStoryString = refinedStoryString);
    this.sessioneService.refinedStory$.pipe(takeUntil(this.destroy$)).subscribe(lista => {
      this.chunks = lista;
      this.setActiveChunkIds();
    });
    this.authService.ruolo$.pipe(takeUntil(this.destroy$)).subscribe(ruolo => this.ruolo = ruolo);
  }

  setActiveChunkIds() {
    this.activePanelIds = this.chunks.map((chunk, index) => 'ngb-panel-' + index);
  }

  updateSelectedModel() {
    this.sessioneService.updateSelectedModel(this.selectedModel);
  }

  updateSelectedTemplate() {
    this.sessioneService.updateSelectedTemplate(this.selectedTemplate);
  }

  updateSelectedTestStyle() {
    this.sessioneService.updateSelectedTestStyle(this.testStyle);
  }

  updateIstruzioniScriviTest() {
    this.sessioneService.updateIstruzioniScriviTest(this.istruzioniScriviTest);
  }

  openConfigurationWithConfirmation() {
    if (confirm('Questa operazione causerà la perdità dei dati della sessione.\nAssicurati di aver salvato il lavoro prima di continuare.')) {
      this.router.navigate(['/projects/configuration', this.projectId]);
    }
  }

  onStorySelected(): void {
    const input = this.fileInputDocx.nativeElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.fileName = file.name;
      this.sessioneService.updateFileName(file.name);
      this.uploadFileToActivity(file);
    } else {
      this.fileName = '';
    }
  }

  uploadFileToActivity(file: File): void {
    this.spinnerService.show();
    this.apiService.uploadFile(file).subscribe(
      (response: { filename: string, contents: string | string[] }) => {
        if (Array.isArray(response.contents)) {
          this.sessioneService.updateRefinedStory(response.contents);
        } else {
          this.sessioneService.updateBodySubject(response.contents);
        }
        this.uploadCompleted = true;
        this.spinnerService.hide();
      },
      (error: any) => {
        console.error('There was an error uploading the file!', error);
        this.spinnerService.hide();
      }
    );
  }

  clearFile(): void {
    this.fileName = '';
    this.sessioneService.updateFileName('');
  }

  goToTests() {
    this.activeTab = 'testing';
  }

  openGeneraTestsModal(modalContent: any) {
    this.modalService.dismissAll();
    this.modalService.open(modalContent, { size: 'lg', scrollable: true });
  }

  generaTests() {
    console.log('Starting generaTests');
    this.modalService.dismissAll();
    this.activeTab = 'testing';

    setTimeout(() => {
      console.log('Tab should be changed, initiating test generation');
      this.sessioneService.initiateGenerateTests();

      this.changeDetectorRef.detectChanges();

      if (this.testComponent) {
        /*console.log('TestComponent found, triggering generation');
        console.log('Project ID:', this.testComponent.projectId);
        console.log('Project Language:', this.testComponent.projectLanguage);
        console.log('Selected Model:', this.testComponent.selectedModel);
        console.log('Project Template:', this.testComponent.selectedTemplate);*/
        this.testComponent.generateTests()
      } else {
        console.error('TestComponent not found after tab change');
      }

      console.log('generando test');
    }, 0);
  }

  fetchTemplateNames(projectId: string) {
    this.apiService.getTemplates(projectId).subscribe(
      data => {
        this.sessioneService.updateTemplates(data);
      },
      error => {
        console.error('Error fetching template names', error);
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
